import styles from './PageSizeSelector.module.css'
import Select from 'react-select';

// Selettore del numero di elementi per pagina
export default function PageSizeSelector({pageSizes, pageSize, setPageSize, resetPage}) {

    const mapListToOptions = (list) => {
        return list.map((element, i) => { return { value: element, label: element }});
    }

return (
    <div className={styles.pageSizeSelection}>
        <label className={styles.controlPanelLabel}>Page Size</label>
        <Select
            className={styles.pageSizeSelect}
            value={pageSize} onChange={(e) => {
                setPageSize(e);
                resetPage({'pageSize': e.value});
            }}
            options={mapListToOptions(pageSizes)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'var(--primary-color)',
                    primary75: 'var(--primary-color75)',
                    primary50: 'var(--primary-color50)',
                    primary25: 'var(--primary-color25)',
                    
                },
              })}/>
    </div>
);
}