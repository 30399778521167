import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from './ControlPanel.module.css'
import InfoContext from "../../../context/InfoContext";
import SelectedTypeContext from "../../../context/dataVisualization/selectedTypeContext";
import SelectedMachinesContext from "../../../context/dataVisualization/selectedMachinesContext";
import SelectedStageContext from "../../../context/dataVisualization/selectedStageContext";
import SelectedAxisContext from "../../../context/dataVisualization/selectedAxisContext";
import MachineTypeSelector from "../MachineTypeSelector/MachineTypeSelector";
import MachineList from "../MachineList/MachineList";
import MachineSelector from "../MachineSelector/MachineSelector";
import AxisStageSelector from "../AxisStageSelector/AxisStageSelector";
import { getMachinesOfTypeAPI, getTestsOfMachineAPI, setMachineTestLabelAPI, setMachineTestSetAPI } from '../../../api/machinesManagementAPI';
import { alertMessage } from "../../../utils/alerts";

// Pannello di controllo per la selezione dei test da visualizzare e l'aggiornamento delle etichette e degli insiemi di apprendimento
export default function ControlPanel({loadData}) {
    // Context utilizzati
    const {info} = useContext(InfoContext);
    const {selectedType, setSelectedType} = useContext(SelectedTypeContext);
    const {selectedMachines, setSelectedMachines} = useContext(SelectedMachinesContext);
    const {selectedStage, setSelectedStage} = useContext(SelectedStageContext);
    const {selectedAxis, setSelectedAxis} = useContext(SelectedAxisContext);

    /* Elenco macchine del tipo selezionato
    [] | ["machine_id"]
    */
    const [machines, setMachines] = useState([]);
    /* Elenco test della macchina selezionata
    [] | [{"test": "testId", "label": "benchmark" | "anomaly" | "unknown" | "none", "set": "train" | "test" | "none"}]
    */
    const [tests, setTests] = useState([]);
    /* Macchina selezionata
    null | {"value": machine_id, ...}
    */
    const [selectedMachine, setSelectedMachine] = useState(null);
    /* Test selezionato
    null | {"value": test_id, dataLabel: label, dataSet: set, ...}
    */
    const [selectedTest, setSelectedTest] = useState(null);

    const location = useLocation();

    const lableAbbreviations = {
        'benchmark': ' [BM]',
        'anomaly': ' [AN]',
        'unknown': ' [UNK]',
        'none': ''
    };

    const setAbbreviations = {
        'train': '(trn)',
        'test': '(tst)',
        'none': '',
        '': ''
    };

    // Listener che inizializza Context SelectedMachines la prima volta che la pagina viene caricata
    useEffect(() => {
        if(info != null && selectedMachines == null){
            let selMachines = {};
            info.machine_types.forEach((type) => {
                selMachines[type] = [];
            });
            setSelectedMachines(selMachines);
        }
    }, [info]);

    // Listener che recupera la lista di macchine quando viene selezionato il tipo o viene caricata la pagina
    useEffect(() => {
        if(location.pathname === "/data-visualization" && selectedType != null){
            setSelectedMachine(null);
            setSelectedTest(null);
            setTests([]);
            loadMachinesOfType(selectedType.value);
        }
    }, [location, selectedType]);

    // Listener che recupera i test di una macchina quando viene selezionata
    useEffect(() => {
        if(selectedMachine != null){
            loadTestsOfMachine(selectedMachine.value);
        }
    }, [selectedMachine]);

    // Recupera le macchine di un tipo
    const loadMachinesOfType = (type) => {
        getMachinesOfTypeAPI(type)
        .then((data) => {
            setMachines(data);
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve machines of type.');
        });
    }

    // Recupera i test di una macchina
    const loadTestsOfMachine = (machine) => {
        getTestsOfMachineAPI(selectedType.value, machine)
        .then((data) => {
            setSelectedTest(null);
            setTests(data);
            if(data.length === 1)
                setSelectedTest({value: data[0].test, label:data[0].test + lableAbbreviations[data[0].label] + setAbbreviations[data[0].set], dataLabel: data[0].label, dataSet: data[0].set})
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve tests of machine.');
        });
    }

    // Inverti per una test selezionato il valore del flag che determina quali test saranno caricati
    const toggleMachineSelected = (index) => {
        setSelectedMachines((machines) => {
            let newMachines = {};
            info.machine_types.forEach((type) => {
                newMachines[type] =  Array.from(machines[type]);
            });
            newMachines[selectedType.value][index].selected = !machines[selectedType.value][index].selected;
            return newMachines;
        });
    }

    // Rimuovi un test da quelli selezionati
    const removeMachine = (index) => {
        setSelectedMachines((machines) => {
            let newMachines = {};
            info.machine_types.forEach((type) => {
                newMachines[type] =  Array.from(machines[type]);
            });
            newMachines[selectedType.value].splice(index, 1);
            return newMachines;
        });
    }

    // Aggiungi un test alla lista di test selezionati
    const addMachine = (machineId, testId, label, set) => {
        if(!selectedMachines[selectedType.value].some((machine) => machine.machineId === machineId && machine.testId === testId)){
            setSelectedMachines((machines) => {
                let newMachines = {};
                info.machine_types.forEach((type) => {
                    newMachines[type] =  Array.from(machines[type]);
                });
                newMachines[selectedType.value].push({
                    machineId,
                    testId,
                    selected: true,
                    label: label,
                    set: set
                });
                return newMachines;
            });
            setSelectedTest(null);
        }
    }

    // Aggiorna l'etichetta di un test
    const setMachineLabel = (index, label) => {
        if (!(label === 'anomaly' && selectedMachines[selectedType.value][index].set === 'train')) {
            setMachineTestLabelAPI(selectedType.value, selectedMachines[selectedType.value][index].machineId, selectedMachines[selectedType.value][index].testId, label)
            .then((data) => {
                // Se la richeista all'API Gateway ha successo aggiorna il Context dei test selezionati
                setSelectedMachines((machines) => {
                    let newMachines = {};
                    info.machine_types.forEach((type) => {
                        newMachines[type] =  Array.from(machines[type]);
                    });

                    newMachines[selectedType.value][index].label = label;
                    if (label === 'none' || label === 'unknown')
                        newMachines[selectedType.value][index].set = '';
                    else {
                        if (newMachines[selectedType.value][index].set === '') {
                            newMachines[selectedType.value][index].set = 'none';
                        }
                    }
                    return newMachines;
                });
            })
            .catch((error) => {
                console.log(error);
                alertMessage('Error', 'Error while trying to update machine test label.');
            });
        }
    }

    // Aggiorna l'insieme di appartenenza di un test
    const setMachineSet = (index, set) => {
        setMachineTestSetAPI(selectedType.value, selectedMachines[selectedType.value][index].machineId, selectedMachines[selectedType.value][index].testId, set)
        .then((data) => {
            // Se la richeista all'API Gateway ha successo aggiorna il Context dei test selezionati
            setSelectedMachines((machines) => {
                let newMachines = {};
                info.machine_types.forEach((type) => {
                    newMachines[type] =  Array.from(machines[type]);
                });

                newMachines[selectedType.value][index].set = set;
                return newMachines;
            });
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to update machine test set.');
        });
    }


    
return (
    <div className={styles.controlPanel}>
        {info != null && <MachineTypeSelector
            machineTypes={info.machine_types}
            type={selectedType}
            selectType={setSelectedType}
        />}
        <MachineList
            machines={selectedType == null? [] : selectedMachines[selectedType.value]}
            toggleMachine={toggleMachineSelected}
            removeMachine={removeMachine}
            setLabel={setMachineLabel}
            setSet={setMachineSet}
        />
        <MachineSelector
            type={selectedType}
            machines={machines}
            selectedMachine={selectedMachine}
            changeMachine={setSelectedMachine}
            tests={tests}
            selectedTest={selectedTest}
            changeTest={setSelectedTest}
            selectedMachines={selectedType == null? [] : selectedMachines[selectedType.value]}
        />
        <button
            className={styles.addMachineButton}
            disabled={selectedMachine == null || selectedTest == null || selectedType == null || selectedMachines[selectedType.value].some((machine) => machine.machineId === selectedMachine.value && machine.testId === selectedTest.value)}
            onClick={() => addMachine(selectedMachine.value, selectedTest.value, selectedTest.dataLabel, selectedTest.dataSet)}
        >Add</button>

        <hr className={styles.separator}/>

        {info != null && <AxisStageSelector
            stages={info.stages}
            axes={info.axes}
            stage={selectedStage}
            changeStage={setSelectedStage}
            axis={selectedAxis}
            changeAxis={(newAxis) => {
                setSelectedAxis((oldAxis) => {
                    if(oldAxis != null && !((oldAxis.value === info.axes[0] || oldAxis.value === info.axes[1]) && (newAxis.value === info.axes[0] || newAxis.value === info.axes[1])))
                        setSelectedStage(null);
                    return newAxis;
                });
            }}
        />}

        {/* Bottone di caricamento dei dati, tra i test selezionati solo quelli con flag "selected" = True saranno caricati */}
        <button
            className={styles.loadButton}
            onClick={loadData}
            disabled={selectedType == null || selectedStage == null || selectedAxis == null || !selectedMachines[selectedType.value].some((machine) => machine.selected)}
        >Load</button>
    </div>
);
}