// Impostazioni per gli assi dei grafici per il motore
const MS2N_AXES_SETTINGS = {
    'speedCmd': [
            //impostazioni per Asse 1
            {
                'max': 150,
                'min': -150,
                'step': 25,
                'minorStep': 5,
            },
            //impostazioni per Asse 2
            {
                'max': 150,
                'min': -150,
                'step': 25,
                'minorStep': 5,
            },
            //impostazioni per Asse 3
            {
                'max': 150,
                'min': -150,
                'step': 25,
                'minorStep': 5,
            },
        ],
    'accCmd': [
            {
                'max': 25,
                'min': -25,
                'step': 5,
                'minorStep': 1,
            },
            {
                'max': 25,
                'min': -25,
                'step': 5,
                'minorStep': 1,
            },
            {
                'max': 25,
                'min': -25,
                'step': 5,
                'minorStep': 1,
            }
        ],
    'motorTorque': [
            {
                'max': 150,
                'min': -150,
                'step': 25,
                'minorStep': 5,
            },
            {
                'max': 150,
                'min': -150,
                'step': 25,
                'minorStep': 5,
            },
            {
                'max': 60,
                'min': -60,
                'step': 10,
                'minorStep': 3,
            },
        ],
    'gearboxTorque': [
            {
                'max': 400,
                'min': -400,
                'step': 40,
                'minorStep': 10,
            },
            {
                'max': 400,
                'min': -400,
                'step': 40,
                'minorStep': 10,
            },
            {
                'max': 400,
                'min': -400,
                'step': 40,
                'minorStep': 10,
            },
        ],
};

export default MS2N_AXES_SETTINGS;