import styles from './MachineList.module.css'
import MachineTestMenuLabel from '../MachineTestMenuLabel/MachineTestMenuLabel';

// Lista dei test selezionati
export default function MachineList({machines, toggleMachine, removeMachine, setLabel, setSet}) {

return (
    <div className={styles.machineListSection}>
        <label className={styles.controlPanelLabel}>Selected Machines:</label>
        <ul className={styles.machineList}>
            {machines.length > 0 && machines.map((machine, i) => { return (
                <li key={machine.machineId + machine.testId} className={styles.machineItemWrapper}>
                    <input
                        className={styles.machineItemCheckbox}
                        type="checkbox"
                        checked={machine.selected}
                        onChange={() => toggleMachine(i)}
                    />
                    <MachineTestMenuLabel
                        index={i}
                        machineId={machine.machineId}
                        testId={machine.testId}
                        label={machine.label}
                        setLabel={setLabel}
                        set={machine.set}
                        setSet={setSet}
                    />
                    <button className={styles.machineItemRmBtn} onClick={() => removeMachine(i)}>Remove</button>
                </li>
            )})}
            {machines.length === 0 && <label className={styles.noResultLabel}>No machines selected</label>}
        </ul>
    </div>
);
}