import styles from './MachineTypeSelector.module.css'
import Select from 'react-select';

// Selettore del tipo di macchine
export default function MachineTypeSelector({machineTypes, type, selectType}) {

    const mapListToOptions = (list) => {
        return list.map((element, i) => { return { value: element, label: element }});
    }

return (
    <div className={styles.machineTypeSelection}>
        <label className={styles.controlPanelLabel}>Machine Type</label>
        <Select
            className={styles.machineTypeSelect}
            value={type} onChange={selectType}
            options={mapListToOptions(machineTypes)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'var(--primary-color)',
                    primary75: 'var(--primary-color75)',
                    primary50: 'var(--primary-color50)',
                    primary25: 'var(--primary-color25)',
                    
                },
              })}/>
    </div>
);
}