// Nomi assi
export const AXES_LABELS = {
    'Axis0001': 'X Master',
    'Axis0002': 'X Slave',
    'Axis0003': 'Y',
}

// Descrizione stage/movimenti
export const STAGES_LABELS = {
    'Axis0001': {
        'Type001': '001.X+ / Yr : speed 30m/min',
        'Type002': '002.X- / Ye : speed 30m/min',
        'Type003': '003.X+ / Ye : speed 30m/min',
        'Type004': '004.X- / Yr : speed 30m/min',
        'Type005': '005.X+ / Ym : speed 30m/min',
        'Type006': '006.X- / Ym : speed 30m/min',
        'Type007': '007.X+ / Ym : speed 130m/min',
        'Type008': '008.X- / Ym : speed 130m/min',
        'Type009': '009.X+ / Ym step : speed 130m/min',
        'Type010': '010.X- / Ym step: speed 130m/min',
        'Type011': '011.X+ / Yr : speed 130m/min',
        'Type012': '012.X- / Ye : speed 130m/min',
        'Type013': '013.X+ / Ye : speed 130m/min',
        'Type014': '014.X- / Yr : speed 130m/min',
    },
    'Axis0002': {
        'Type001': '001.X+ / Yr : speed 30m/min',
        'Type002': '002.X- / Ye : speed 30m/min',
        'Type003': '003.X+ / Ye : speed 30m/min',
        'Type004': '004.X- / Yr : speed 30m/min',
        'Type005': '005.X+ / Ym : speed 30m/min',
        'Type006': '006.X- / Ym : speed 30m/min',
        'Type007': '007.X+ / Ym : speed 130m/min',
        'Type008': '008.X- / Ym : speed 130m/min',
        'Type009': '009.X+ / Ym step : speed 130m/min',
        'Type010': '010.X- / Ym step: speed 130m/min',
        'Type011': '011.X+ / Yr : speed 130m/min',
        'Type012': '012.X- / Ye : speed 130m/min',
        'Type013': '013.X+ / Ye : speed 130m/min',
        'Type014': '014.X- / Yr : speed 130m/min',
    },
    'Axis0003': {
        'Type001': '001.Xe / Y+ : speed 30m/min',
        'Type002': '002.Xr / Y- : speed 30m/min',
        'Type003': '003.Xr / Y+ : speed 30m/min',
        'Type004': '004.Xe / Y- : speed 30m/min',
        'Type005': '005.Xm / Y+ : speed 30m/min',
        'Type006': '006.Xm / Y- : speed 30m/min',
        'Type007': '007.Xm / Y+ : speed 130m/min',
        'Type008': '008.Xm / Y- : speed 130m/min',
        'Type009': '009.Xm / Y+ step : speed 130m/min',
        'Type010': '010.Xm / Y- step : speed 130m/min',
        'Type011': '011.Xe / Y+ : speed 130m/min',
        'Type012': '012.Xr / Y- : speed 130m/min',
        'Type013': '013.Xr / Y+ : speed 130m/min',
        'Type014': '014.Xe / Y- : speed 130m/min',
    }
};