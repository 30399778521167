import { Auth } from 'aws-amplify';

// Estrai IdToken dalla sessione di autenticazione
export const getIdToken = async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
}

// Estrai AccessToken dalla sessione di autenticazione
export const getAccessToken = async () => {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
}