import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import Layout from './pages/Layout/Layout';
import DataVisualizationPage from "./pages/DataVisualization/DataVisualizationPage"
import DataLabelsPage from './pages/DataLabels/DataLabelsPage';
import TrainTestSplitPage from './pages/TrainTestSplit/TrainTestSplitPage';
import BenchmarkRangesPage from './pages/BenchmarkRanges/BenchmarkRagesPage';
import InfoContext from './context/InfoContext';
import IsAdminContext from './context/isAdminContext';
import DataVisualizationContextProvider from './context/dataVisualization/DataVisualizationContextProvider';
import DataLabelsContextProvider from './context/dataLabels/DataLabelsContextProvider'
import TrainTestSplitContextProvider from './context/trainTestSplit/TrainTestSplitContextProvider';

import { Amplify, Cache } from 'aws-amplify';
import awsConfig from './aws-config';
import cacheConfig from './cache-config';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css'
// Inizializza con configurazioni di AWS e della cache
Amplify.configure(awsConfig)
Cache.configure(cacheConfig);

// Definizione dell'applicazione React
function App({ signOut, user }) {
    // Crea State per informazioni generali e per il livello di accesso dell'utente
    const [info, setInfo] = React.useState(null);
    const [isAdmin, setIsAdmin] = React.useState(false);

    return (
        // Wrapper dell'applicazione React per consentire accesso ai Context dichiarati
        <InfoContext.Provider value={{ info, setInfo }}>
            <IsAdminContext.Provider value={{ isAdmin, setIsAdmin }}>
                <DataVisualizationContextProvider>
                    <DataLabelsContextProvider>
                        <TrainTestSplitContextProvider>
                            {/* Dichiarazione del Router e delle pagine di navigazione che compongono l'applicazione*/}
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<Layout signOut={signOut} user={user}/>}>
                                        <Route index element={ <Navigate to="/data-visualization" /> } />
                                        <Route path="/data-visualization" element={<DataVisualizationPage/>} />
                                        <Route path="/data-labels" element={<DataLabelsPage/>} />
                                        <Route path="/train-test-split" element={<TrainTestSplitPage/>} />
                                        <Route path="/benchmark-ranges" element={<BenchmarkRangesPage/>} />
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </TrainTestSplitContextProvider>
                    </DataLabelsContextProvider>
                </DataVisualizationContextProvider>
            </IsAdminContext.Provider>
        </InfoContext.Provider>
    );
}

// Inserisci l'applicazione dentro l'autenticatore di Amply (opzione di registrazione disattivata) per consentire l'uso solo ad untenti autorizzati
export default withAuthenticator(App, {hideSignUp: true});
