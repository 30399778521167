/* Elenco di test selezionati per ogni tipo
null | {
    "machine_type": [
        {
            machineId: machine_id,
            testId: test_id,
            selected: true | false,
            label: "benchmark" | "anomaly" | "unknown" | "none",
            set: "train" | "test" | "none"
        }
    ]
}
*/
import { createContext } from "react";
const SelectedMachinesContext = createContext({
    selectedMachines: null,
    setSelectedMachines: (selectedMachines) => {}
  });
export default SelectedMachinesContext;