import React, { useContext } from "react";
import styles from './MachineTestMenuLabel.module.css';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {usePopupState, bindTrigger, bindMenu} from 'material-ui-popup-state/hooks';
import IsAdminContext from "../../../context/isAdminContext";


// Componente di testo per la lista di macchine selezionate con menu drop down 
const MachineTestMenuLabel = ({index, machineId, testId, label, setLabel, set, setSet}) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    const {isAdmin} = useContext(IsAdminContext);

    const lableAbbreviations = {
        'benchmark': ' [BM]',
        'anomaly': ' [AN]',
        'unknown': ' [UNK]',
        'none': ''
    };

    const setAbbreviations = {
        'train': '(trn)',
        'test': '(tst)',
        'none': '',
        '': ''
    };

  return (
    <div className={styles.machineTestLabelWrapper}>
        {/* Testo descrittivo del test */}
        {label !== 'none' && set !== '' && set !== 'none' &&
            <Button className={styles.machineTestClickableLabel} disableRipple variant="contained" {...bindTrigger(popupState)}>{machineId + ' - ' + testId + lableAbbreviations[label] + setAbbreviations[set]}</Button>
        }
        {label !== 'none' && (set === '' || set === 'none') &&
            <Button className={styles.machineTestClickableLabel} disableRipple variant="contained" {...bindTrigger(popupState)}>{machineId + ' - ' + testId + lableAbbreviations[label] + '         '}</Button>
        }
        {label === 'none' && (set === '' || set === 'none') &&
            <Button className={styles.machineTestClickableLabel} disableRipple variant="contained" {...bindTrigger(popupState)}>{machineId + ' - ' + testId  + '                 '}</Button>
        }

        {/* Menu drop down */}
        <Menu className={styles.machinePopupMenu} {...bindMenu(popupState)}>
            <MenuItem className={styles.machinePopupMenuItem}
                onClick={() => {
                    navigator.clipboard.writeText(machineId + ' - ' + testId);
                    popupState.close();
                }}
            >Copy</MenuItem>
            {/* Funzionalità riservate ad utenti admin */}
            {isAdmin && 
                <div>
                    <hr className={styles.menuSeparator}/>
                    {label !== 'benchmark' &&
                        <MenuItem className={styles.machinePopupMenuItem}
                            onClick={() => {
                                if(isAdmin)
                                    setLabel(index, 'benchmark');
                                popupState.close();
                            }}
                        >{'Label as Benchmark'}</MenuItem>
                    }
                    {label !== 'anomaly' &&
                        <MenuItem disabled={set === 'train'} className={styles.machinePopupMenuItem}
                            onClick={() => {
                                if(isAdmin)
                                    setLabel(index, 'anomaly');
                                popupState.close();
                            }}
                        >{'Label as Anomaly'}</MenuItem>
                    }
                    {label !== 'unknown' &&
                        <MenuItem className={styles.machinePopupMenuItem}
                            onClick={() => {
                                if(isAdmin)
                                    setLabel(index, 'unknown');
                                popupState.close();
                            }}
                        >{'Label as Unknown'}</MenuItem>
                    }
                    {label !== 'none' &&
                        <MenuItem className={styles.machinePopupMenuItem}
                            onClick={() => {
                                if(isAdmin)
                                    setLabel(index, 'none');
                                popupState.close();
                            }}
                        >{'Remove Label'}</MenuItem>
                    }
                    {(label === 'benchmark' || label === 'anomaly') &&
                        <div>
                            <hr className={styles.menuSeparator}/>
                            {set !== 'train' &&
                                <MenuItem disabled={label !== 'benchmark'} className={styles.machinePopupMenuItem}
                                    onClick={() => {
                                        if(isAdmin)
                                            setSet(index, 'train');
                                        popupState.close();
                                    }}
                                >{'Add to Train Set'}</MenuItem>
                            }
                            {set !== 'test' &&
                                <MenuItem className={styles.machinePopupMenuItem}
                                    onClick={() => {
                                        if(isAdmin)
                                            setSet(index, 'test');
                                        popupState.close();
                                    }}
                                >{'Add to Test Set'}</MenuItem>
                            }
                            {set !== 'none' && set !== '' &&
                                <MenuItem className={styles.machinePopupMenuItem}
                                    onClick={() => {
                                        if(isAdmin)
                                            setSet(index, 'none');
                                        popupState.close();
                                    }}
                                >{'Remove from ' + (set === 'train' ? 'Train Set' : 'Test Set')}</MenuItem>
                            }
                        </div>
                    }
                </div>
            }
        </Menu>
    </div>
  )
}

export default MachineTestMenuLabel;