import React, { useState, useContext, useEffect, useRef } from "react";
import styles from './DataVisualization.module.css'
import SelectedTypeContext from "../../context/dataVisualization/selectedTypeContext";
import SelectedMachinesContext from "../../context/dataVisualization/selectedMachinesContext";
import SelectedStageContext from "../../context/dataVisualization/selectedStageContext";
import SelectedAxisContext from "../../context/dataVisualization/selectedAxisContext";
import MachineTestsDataContext from "../../context/dataVisualization/machineTestsDataContext";
import LoadedSelectionsContext from "../../context/dataVisualization/loadedSelectionsContext";
import SelectedPlotInfoContext from "../../context/dataVisualization/selectedPlotInfo";
import PlotOptionsContext from "../../context/dataVisualization/plotOptionsContext";
import { getMachineTestsData } from "../../api/machinesManagementAPI";
import ControlPanel from "./ControlPanel/ControlPanel";
import PlotPanel from "./PlotPanel/PlotPanel";
import TablePanel from "./TablePanel/TablePanel";
import { alertMessage } from "../../utils/alerts";
import { generatePdfReport } from "../../utils/generatePdfReport";
import {isMobile} from 'react-device-detect';

// Pagina di visualizzazione dei dati
export default function DataVisualizationPage() {
    // Context usati
    const {selectedType} = useContext(SelectedTypeContext);
    const {selectedMachines} = useContext(SelectedMachinesContext);
    const {selectedStage} = useContext(SelectedStageContext);
    const {selectedAxis} = useContext(SelectedAxisContext);
    const {machineTestsData, setMachineTestsData} = useContext(MachineTestsDataContext);
    const {setLoadedSelections} = useContext(LoadedSelectionsContext);
    const {selectedPlotInfo, setSelectedPlotInfo} = useContext(SelectedPlotInfoContext);
    const {plotOptions, setPlotOptions} = useContext(PlotOptionsContext);

    // Reference al componente figlio per richiamare metodo di aggiornamento del grafico
    const plotPanelRef = useRef();
    /* Testo digitato come commento per il report pdf
    "" | "... comment ..."
    */
    const [reportText, setReportText] = useState('');

    // Binding tasto INVIO con caricamento dei dati
    useEffect(() => {
        const eventListener = (e) => {
            if(e.keyCode  === 13){
                loadSelectedMachinesData();
            }
        }

        window.addEventListener('keydown', eventListener);
        return () => {
            window.removeEventListener('keydown', eventListener);
        };
    });

    // Caricamento dati
    const loadSelectedMachinesData = () => {
        if(selectedType != null && selectedStage != null && selectedAxis != null) {
            const selectedMachineTests = selectedMachines[selectedType.value].filter((machine) => machine.selected);
            getMachineTestsData(selectedType.value, selectedMachineTests, selectedStage.value, selectedAxis.value)
            .then((data) => {
                const minRange = data[0];
                const maxRange = data[1];
                const testsData = data[2];
                if(testsData.length > 0) {
                    // Salvataggio dei valori selezionati per caricare i dati in Context
                    const selections = {
                        type: selectedType.value,
                        stage: selectedStage.value,
                        axis: selectedAxis.value
                    };
                    setLoadedSelections(selections);
                    // Salvataggio dei dati caricati in Context
                    setMachineTestsData({
                        minRange,
                        maxRange,
                        testsData,
                    });
                    // Aggiorna il grafico solo se non si è da dispositivo mobile
                    if(!isMobile) {
                        let pltInfo = selectedPlotInfo?.value;
                        let pltOptions = plotOptions;
                        // Inizializza il dato selezionato da mostrare nel grafico a gearboxTorque
                        if(pltInfo == null) {
                            setSelectedPlotInfo({value: 'gearboxTorque', label:'Gearbox Torque'});
                            pltInfo = 'gearboxTorque';
                        }
                        // Inizializza opzioni del grafico se necessario
                        if(pltOptions == null) {
                            pltOptions = {
                                showBenchmarkRangeArea: false,
                                showCriticalPointsMarkers: false,
                            };
                            setPlotOptions(pltOptions);
                        }

                        // Aggiorna dati grafico
                        plotPanelRef.current.updatePlot(minRange, maxRange, testsData, pltInfo, pltOptions, selections);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                alertMessage('Error', 'Error while trying to retrieve data of machine tests.')
            });
        }
    }
    
return (
    <div className={styles.pageContent}>
        <div className={styles.mainContent}>
            <ControlPanel loadData={loadSelectedMachinesData}/>
            {!isMobile &&
                <div className={styles.dataVisualizationPanel}>
                    <PlotPanel ref={plotPanelRef}/>
                </div>
            }
        </div>
        
        <TablePanel/>

        {/* Pannello generazione report per dispositivi non mobile */}
        {!isMobile &&
            <>
                <hr className={styles.separator}/>
                <div className={styles.reportPanel}>
                    <p className={styles.reportTitle}>Report</p>
                    <textarea className={styles.reportTextArea} name="postContent" value={reportText} onChange={(e) => setReportText(e.target.value)} cols={100} rows={5}/>
                    <button
                        className={styles.generateReportButton}
                        onClick={() => {generatePdfReport(selectedType.value, selectedAxis.value, selectedStage.value, selectedPlotInfo.value, reportText)}}
                        disabled={machineTestsData == null || machineTestsData.testsData == null || machineTestsData.testsData.length === 0}
                    >Generate Report</button>
                </div>
            </>
        }
    </div>
);
}