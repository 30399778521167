import Select from 'react-select';
import styles from './AxisStageSelector.module.css';
import classNames from 'classnames';
import {AXES_LABELS, STAGES_LABELS} from '../../../data/testOptionsLabels';

// Selettori per asse e stage
export default function AxisStageSelector({stages, axes, stage, changeStage, axis, changeAxis}) {

    const mapListToOptions = (list, labels) => {
        return list.map((element, i) => { return { value: element, label: labels[element] }});
    }

return (
    <div className={styles.axisStageSelector}>
        <div className={styles.axisSelectWrapper}>
            <label className={classNames(styles.controlPanelLabel, styles.axisSelectLabel)}>Axis</label>
            <Select
                className={styles.axisSelect}
                value={axis} onChange={changeAxis}
                options={mapListToOptions(axes, AXES_LABELS)}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--primary-color)',
                        primary75: 'var(--primary-color75)',
                        primary50: 'var(--primary-color50)',
                        primary25: 'var(--primary-color25)',
                        
                    },
                })}/>
        </div>
        {axis != null && 
            <div className={styles.stageSelectWrapper}>
                <label className={classNames(styles.controlPanelLabel, styles.stageSelectLabel)}>Stage</label>
                <Select
                    className={styles.stageSelect}
                    value={stage} onChange={changeStage}
                    options={mapListToOptions(stages, STAGES_LABELS[axis.value])}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'var(--primary-color)',
                            primary75: 'var(--primary-color75)',
                            primary50: 'var(--primary-color50)',
                            primary25: 'var(--primary-color25)',
                            
                        },
                    })}/>
            </div>
        }
    </div>
);
}