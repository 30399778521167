import styles from './BenchmarkRangesList.module.css'
import classNames from 'classnames';

// Lista dei Benchmark Range
export default function BenchmarkRangesList({benchmarkRanges, deleteRange}) {

return (
    <div className={styles.listPanel}>
        <table className={styles.table}>
            <thead className={styles.tableHead}>
                <tr>
                    <th className={styles.tableHeadCell}>Range Id</th>
                    <th className={styles.tableHeadCell}></th>
                </tr>
            </thead>
            {benchmarkRanges != null && benchmarkRanges.length > 0 &&
                <tbody className={styles.tableBody}>
                    {benchmarkRanges.map((benchmarkRange, i) => (  
                        <tr  key={benchmarkRange} className={i % 2 === 0 ? styles.evenTableRow: styles.oddTableRow}>
                            <td className={classNames(styles.tableCell, styles.cellNoNewLine)}>{benchmarkRange}</td>
                            <td className={classNames(styles.tableCell, styles.cellNoNewLine)}>
                                <button className={styles.benchmarkRmBtn} onClick={() => deleteRange(i)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            }
            {(benchmarkRanges == null || benchmarkRanges.length === 0) &&
                <tbody className={styles.tableBody}>
                    <tr className={styles.tableRow}>  
                        <td colSpan="3" className={classNames(styles.tableCell, styles.tableEmptyCell)}></td> 
                    </tr>
                </tbody>
            }
        </table>
    </div>
);
}