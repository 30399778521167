import React, { useContext, useRef, forwardRef, useImperativeHandle } from "react";
import styles from './PlotPanel.module.css'
import classNames from 'classnames'
import MachineTestsDataContext from "../../../context/dataVisualization/machineTestsDataContext";
import LoadedSelectionsContext from "../../../context/dataVisualization/loadedSelectionsContext";
import PlotDataContext from "../../../context/dataVisualization/plotDataContext";
import SelectedPlotInfoContext from "../../../context/dataVisualization/selectedPlotInfo";
import PlotOptionsContext from "../../../context/dataVisualization/plotOptionsContext";
import Plot from 'react-plotly.js';
import Select from 'react-select';
import plotInfoSettings from "./PlotInfoSettings";
import TIME_WINDOW_SETTING from "./timeWindowSettings";
import MS2N_AXES_SETTINGS from "./MS2N_AxesSettings";
import MS2N_SPECS from "../../../data/MS2N_specifications";
import {AXES_LABELS, STAGES_LABELS} from '../../../data/testOptionsLabels';
// import MSK_AXES_SETTINGS from "./MSK_AxesSettings";
// import MSK_SPECS from "../../../data/MSK_specifications";

// Pannello del grafico
const PlotPanel = forwardRef((props, ref) => {
    const componentRef = useRef()

    // Context utilizzati
    const {machineTestsData} = useContext(MachineTestsDataContext);
    const {loadedSelections} = useContext(LoadedSelectionsContext);
    const {plotData, setPlotData} = useContext(PlotDataContext);
    const {selectedPlotInfo, setSelectedPlotInfo} = useContext(SelectedPlotInfoContext);
    const {plotOptions, setPlotOptions} = useContext(PlotOptionsContext);

    // Permetti al componente genitore di richiamare un metodo del componente figlio
    useImperativeHandle(ref, () => ({
        updatePlot(minRange, maxRange, data, plotInfo, pltOptions, selectedOptions) {
            updatePlotData(minRange, maxRange, data, plotInfo, pltOptions, selectedOptions);
        }
    }));

    const mapListToOptions = (list) => {
        return list.map((element, i) => { return { value: element, label: plotInfoSettings[element]['label'] }});
    }

    // Aggiorna Context che controlla l'aspetto del grafico
    const updatePlotData = (minRange, maxRange, testsData, plotInfo, pltOptions, selectedOptions) => {
        if(testsData != null && testsData.length > 0 && plotInfo != null) {
            // Resetta valori del Context
            setPlotData([]);
            // Converti asse e stage in valori interi
            const axisInt = parseInt(selectedOptions.axis.split('Axis')[1]);
            const stageInt = parseInt(selectedOptions.stage.split('Type')[1]);

            //Genera array del tempo (asse X)
            const dataFrequency = testsData[0].data.info['tSamples']
            const timeWindow = testsData[0].data.data[plotInfo].length;
            const sampleIndexes = Array.from(Array(timeWindow).keys())
            const time = sampleIndexes.map((k) => k * dataFrequency);

            // Imposta titolo e range per asse del tempo (X)
            const maxTime = TIME_WINDOW_SETTING[selectedOptions.type][selectedOptions.axis][selectedOptions.stage];
            const xAxis = {
                title: 'Time [s]',
                zeroline: false,
                range: [-0.1, maxTime + 0.1],
            };

            // Recupera specifiche motore e impostazioni degli assi
            const specs = MS2N_SPECS;
            const axesSettings = MS2N_AXES_SETTINGS;

            // Aggiungi asse y principale
            const yAxes = [{
                title: plotInfoSettings[plotInfo]['label'] + ' ' + plotInfoSettings[plotInfo]['unit'],
                zeroline: false,
                tickmode: 'linear',
                ticks: 'inside',
                tick0: 0,
                dtick: axesSettings[plotInfo][axisInt-1]['step'],
                ticklen: 6,
                tickwidth: 2,
                minor: {
                    ticks: 'inside',
                    dtick: axesSettings[plotInfo][axisInt-1]['minorStep'],
                    showgrid: true,
                    ticklen: 4,
                    tickwidth: 1,
                },
                range: [axesSettings[plotInfo][axisInt-1]['min'], axesSettings[plotInfo][axisInt-1]['max']]
            }];


            const data = [];
            const colors = ['blue', 'green', 'darkred',  'blueviolet', 'darkgoldenrod', 'sienna', 'deepskyblue', 'lime', 'coral'];

            // Aggiungi soglie massime per gearbox torque se è il dato mostrato
            if(plotInfo === 'gearboxTorque'){
                data.push({
                    x: time,
                    y: Array(timeWindow).fill(specs['max_torque_gearbox']),
                    name: 'Max Range',
                    showlegend: false,
                    type: "scatter",
                    mode: "lines",
                    line: {
                        color: "red",
                    },
                    yaxis: 'y1',
                });
                data.push({
                    x: time,
                    y: Array(timeWindow).fill(-specs['max_torque_gearbox']),
                    name: 'Min Range',
                    showlegend: false,
                    type: "scatter",
                    mode: "lines",
                    line: {
                        color: "red",
                    },
                    yaxis: 'y1',
                });
            }

            // Aggiungi area del benchmark range al grafico se l'opzione è attiva
            const rangesAreaColor = '#1fc12533';
            if(pltOptions.showBenchmarkRangeArea && minRange != null && maxRange != null) {
                // Soglia minima
                const yMinData = [...minRange.data.data[plotInfo]];
                data.push({
                    x: time,
                    y: yMinData,
                    name: plotInfoSettings[plotInfo]['label'] + ' Min Range',
                    showlegend: false,
                    type: "scatter",
                    mode: "lines",
                    line: {color: rangesAreaColor},
                    yaxis: 'y1',
                });
                // Soglia massima
                const yMaxData = [...maxRange.data.data[plotInfo]];
                data.push({
                    x: time,
                    y: yMaxData,
                    name: plotInfoSettings[plotInfo]['label'] + ' Max Range',
                    showlegend: false,
                    type: "scatter",
                    fill: "tonexty",
                    fillcolor: rangesAreaColor,
                    mode: "lines",
                    line: {color: rangesAreaColor},
                    yaxis: 'y1',
                });
            }


            // Aggiungi il dato selezionato per tutti i test caricati
            testsData.forEach((machineTestData, i) => {
                const yData = [...machineTestData.data.data[plotInfo]];
                // Indiviua i punti critici se l'opzione è attiva
                let criticalPointsX = [];
                let criticalPointsY = [];
                const criticalPointsIndexes = [];
                const TOLERANCE_RANGE = 5
                if(pltOptions.showCriticalPointsMarkers && minRange != null && maxRange != null && plotInfo === 'gearboxTorque') {
                    const yMinData = [...minRange.data.data[plotInfo]];
                    const yMaxData = [...maxRange.data.data[plotInfo]];
                    for(let i = 0; i < yData.length; i++){
                        if(yData[i] < yMinData[i]){
                            const close_values = yMinData.slice( Math.max(i - TOLERANCE_RANGE, 0),  Math.min(i + TOLERANCE_RANGE + 1, yData.length))
                            if (yData[i] < Math.min(...close_values)) {
                                criticalPointsX.push(time[i]);
                                criticalPointsY.push(yData[i]);
                                criticalPointsIndexes.push(i);
                            }
                        }
                        if(yData[i] > yMaxData[i]){
                            const close_values = yMaxData.slice(Math.max(i - TOLERANCE_RANGE, 0),  Math.min(i + TOLERANCE_RANGE + 1, yData.length))
                            if (yData[i] > Math.max(...close_values)) {
                                criticalPointsX.push(time[i]);
                                criticalPointsY.push(yData[i]);
                                criticalPointsIndexes.push(i);
                            }
                        }
                    }
                    // Riduci le sequenze ininterrotte di punti critici per "alleggerire" il grafico
                    const filteredIndexes = [];
                    const CRIT_POINT_N = 20;
                    let crit_point_sequence = 0
                    for(let i=1; i < criticalPointsIndexes.length - 1; i++){
                        if((criticalPointsIndexes[i] - 1 === criticalPointsIndexes[i-1]) && (criticalPointsIndexes[i] + 1 === criticalPointsIndexes[i+1])) {
                            crit_point_sequence = crit_point_sequence + 1
                            if (crit_point_sequence >= 1 && crit_point_sequence < CRIT_POINT_N)
                                filteredIndexes.push(i);
                            else
                                crit_point_sequence = 0;
                        } else {
                            crit_point_sequence = 0;
                        }
                    }
                    criticalPointsX = criticalPointsX.filter((value, index) => !filteredIndexes.includes(index));
                    criticalPointsY = criticalPointsY.filter((value, index) => !filteredIndexes.includes(index));
                }
                // Aggiungi i dati dei test
                data.push({
                    x: time,
                    y: yData,
                    name: plotInfoSettings[plotInfo]['label'] + ' ' + machineTestData.machineId + ' ' + machineTestData.testId,
                    type: "scatter",
                    mode: "lines",
                    line: {color: colors[i % colors.length], width: 0.75},
                    yaxis: 'y1',
                });
                // Aggiungi i punti critici ai dati da plottare se l'opzione è attiva
                if(pltOptions.showCriticalPointsMarkers && minRange != null && maxRange != null && criticalPointsX.length > 0 && plotInfo === 'gearboxTorque') {
                    data.push({
                        x: criticalPointsX,
                        y: criticalPointsY,
                        name: plotInfoSettings[plotInfo]['label'] + ' ' + machineTestData.machineId + ' ' + machineTestData.testId + ' (CP)',
                        type: "scatter",
                        mode: "markers",
                        marker: {
                            size: 10,
                            symbol: 'x-thin',
                            line: {
                                color: colors[i % colors.length],
                                width: 3
                            },
                        },
                        yaxis: 'y1',
                    });
                }
            });

            // Aggiungi al grafico i dati secondari associati con il dato selezionato
            plotInfoSettings[plotInfo]['secondaryInfo'].forEach((dataInfo, i) => {
                // Aggiungi asse Y secondario
                yAxes.push({
                    title: plotInfoSettings[dataInfo]['label'] + ' ' + plotInfoSettings[dataInfo]['unit'],
                    titlefont: {color: plotInfoSettings[dataInfo]['secondaryInfoColor']},
                    tickfont: {color: plotInfoSettings[dataInfo]['secondaryInfoColor']},
                    zeroline: false,
                    tickmode: 'linear',
                    ticks: 'inside',
                    tick0: 0,
                    dtick: axesSettings[dataInfo][axisInt-1]['step'],
                    ticklen: 6,
                    tickwidth: 2,
                    showgrid: false,
                    minor: {
                        ticks: 'inside',
                        dtick: axesSettings[dataInfo][axisInt-1]['minorStep'],
                        showgrid: false,
                        ticklen: 4,
                        tickwidth: 1,
                    },
                    side: 'right',
                    overlaying: 'y1',
                    anchor: 'free',
                    autoshift: true,
                    shift: i === 0 ? 3 : 15,
                    range: [axesSettings[dataInfo][axisInt-1]['min'], axesSettings[dataInfo][axisInt-1]['max']]
                });
                // Aggiungi valori del dato secondario
                const yData = [...testsData[0].data.data[dataInfo]];
                data.push({
                    x: time,
                    y: yData,
                    name: plotInfoSettings[dataInfo]['label'],
                    type: "scatter",
                    mode: "lines",
                    line: {
                        dash: 'dashdot',
                        color: plotInfoSettings[dataInfo]['secondaryInfoColor'],
                    },
                    yaxis: i === 0 ? 'y2' : 'y3',
                });
            });

            // Genera nuovo titolo
            const newTitle = selectedOptions.type + ' - ' + AXES_LABELS[selectedOptions.axis] + ' - ' + STAGES_LABELS[selectedOptions.axis][selectedOptions.stage] + ' - ' + plotInfoSettings[plotInfo]['label'];
            
            // Aggiorna Contest PlotData
            setPlotData({
                title: newTitle,
                data: data,
                xAxis: xAxis,
                yAxes: yAxes,
                rightPadding: (yAxes.length - 1) * 75,
            });
        } else {
            setPlotData({
                title: '',
                data: [],
                xAxis: {autotick: true},
                yAxes: [],
                rightPadding: 60,
            });
        }
    }
    
return (
    <div className={styles.plotPanel} ref={componentRef} id='data-visualization-plot'>
        <div className={styles.plotOptionsPanel}>
            {/* Selettore del dato da visualizzare */}
            <label className={classNames(styles.dataVisualizationLabel, styles.plotInfoSelectLabel)}>Plot info</label>
            <Select
                className={styles.plotInfoSelect}
                value={selectedPlotInfo}
                onChange={(e) => {
                    updatePlotData(machineTestsData.minRange, machineTestsData.maxRange, machineTestsData.testsData, e.value, plotOptions, loadedSelections);
                    setSelectedPlotInfo(e);
                }}
                options={mapListToOptions(machineTestsData == null || machineTestsData.testsData == null || machineTestsData.testsData.length === 0 ? [] : Object.keys(machineTestsData.testsData[0].data.data))}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--primary-color)',
                        primary75: 'var(--primary-color75)',
                        primary50: 'var(--primary-color50)',
                        primary25: 'var(--primary-color25)',
                        
                    },
                })}
            />
            
            {/* CheckBox delle opzioni del grafico */}
            <div className={styles.plotOptionCheckboxesWrapper}>
                <div className={styles.plotOptionCheckboxWrapper}>
                    <input
                        className={styles.plotOptionCheckbox}
                        type="checkbox"
                        checked={(plotOptions != null ? plotOptions.showBenchmarkRangeArea : false)}
                        onChange={() => {
                            updatePlotData(machineTestsData.minRange, machineTestsData.maxRange, machineTestsData.testsData, selectedPlotInfo.value, {
                                showBenchmarkRangeArea: !plotOptions.showBenchmarkRangeArea,
                                showCriticalPointsMarkers: plotOptions.showCriticalPointsMarkers,
                            }, loadedSelections)
                            setPlotOptions((options) => {
                                return {
                                    showBenchmarkRangeArea: !options.showBenchmarkRangeArea,
                                    showCriticalPointsMarkers: options.showCriticalPointsMarkers,
                                }
                            }
                        )}}
                        disabled={plotData == null || machineTestsData.minRange == null || machineTestsData.maxRange == null}
                    />
                    <label className={styles.plotOptionCheckboxLabel}>Show Benchmark Range Area</label>
                </div>
                <div className={styles.plotOptionCheckboxWrapper}>
                    <input
                        className={styles.plotOptionCheckbox}
                        type="checkbox"
                        checked={(plotOptions != null ? plotOptions.showCriticalPointsMarkers : false)}
                        onChange={() => {
                            updatePlotData(machineTestsData.minRange, machineTestsData.maxRange, machineTestsData.testsData, selectedPlotInfo.value, {
                                showBenchmarkRangeArea: plotOptions.showBenchmarkRangeArea,
                                showCriticalPointsMarkers: !plotOptions.showCriticalPointsMarkers,
                            }, loadedSelections)
                            setPlotOptions((options) => {
                                return {
                                    showBenchmarkRangeArea: options.showBenchmarkRangeArea,
                                    showCriticalPointsMarkers: !options.showCriticalPointsMarkers,
                                }
                            }
                        )}}
                        disabled={plotData == null || machineTestsData.minRange == null || machineTestsData.maxRange == null}
                    />
                    <label className={styles.plotOptionCheckboxLabel}>Show Critical Points Markers</label>
                </div>
            </div>

            {/* Bottone per il reset della vista del grafico */}
            <button
                className={styles.resetPlotButton}
                onClick={() => updatePlotData(machineTestsData.minRange, machineTestsData.maxRange, machineTestsData.testsData, selectedPlotInfo.value, plotOptions, loadedSelections)}
                disabled={plotData == null}
            >Reset</button>
        </div>

        
        <Plot
            className={styles.plot}
            data={plotData != null ? plotData.data : []}
            divId="plotlyChart"
            useResizeHandler
            layout={{
                autosize: true,
                dragmode: 'pan',
                height:  Math.max(window.innerHeight * 0.75, 475) + Math.max(Math.round((plotData != null && plotData.data != null ? plotData.data.length : 0) / Math.floor(window.innerWidth / 700)), 0) * 30,
                title: {
                    text: plotData != null ? '<b>' + plotData.title + '</b>' : '',
                    x: 0.02,
                    y: 0.98,
                    yanchor: 'top',
                    xanchor: 'left',
                    font: {
                        size: 14,
                    }
                },
                margin: {
                    l: 60,
                    r: plotData != null ? plotData.rightPadding : 75,
                    b: 50,
                    t: 50,
                    pad: 0
                },
                legend: {
                    x: 0,
                    y: -0.2,
                    yanchor: 'top',
                    xanchor: 'left',
                    orientation: "h",
                    traceorder: 'normal',
                    font: {
                        size: 14,
                    }
                },
                xaxis: plotData != null ? plotData.xAxis : {autotick: true},
                yaxis: plotData != null && plotData.yAxes?.length > 0 ? plotData.yAxes[0] : {autotick: true},
                yaxis2: plotData != null && plotData.yAxes?.length > 1 ? plotData.yAxes[1] : {autotick: true},
                yaxis3: plotData != null && plotData.yAxes?.length > 2 ? plotData.yAxes[2] : {autotick: true},
            }}
            config={{
                displayModeBar: true,
                scrollZoom: true,
                showLink: false,
                modeBarButtonsToRemove: ['autoScale2d', 'resetScale2d'],
            }}/>
        </div>
);
});

export default PlotPanel;