import { API } from 'aws-amplify';
import { API_NAME } from '../aws-config';
import { getIdToken } from '../utils/authTokens';

// Recupera dat iegnerali e costanti
export const getInfoAPI = async () => {
    const IdToken = await getIdToken();
    const headers = {
        "Authorization": IdToken
    }
    const response = API.get(API_NAME, 'info', {headers});
    return response;
}