import React, { useState } from "react";
import SelectedTypeContext from "./selectedTypeContext";
import SelectedMachinesContext from "./selectedMachinesContext";
import SelectedStageContext from "./selectedStageContext";
import SelectedAxisContext from "./selectedAxisContext";
import MachineTestsDataContext from "./machineTestsDataContext";
import LoadedSelectionsContext from "./loadedSelectionsContext";
import PlotDataContext from "./plotDataContext";
import SelectedPlotInfo from "./selectedPlotInfo";
import PlotOptionsContext from "./plotOptionsContext";

// Componente wrapper per dare accesso ai Context definiti per la pagina
export default function DataVisualizationContextProvider({children}) {
    // State usati per contenere e modificare i valori dei Context
    const [selectedType, setSelectedType] = useState(null);
    const [selectedMachines, setSelectedMachines] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [selectedAxis, setSelectedAxis] = useState(null);
    const [machineTestsData, setMachineTestsData] = useState(null);
    const [loadedSelections, setLoadedSelections] = useState([]);
    const [plotData, setPlotData] = useState(null);
    const [selectedPlotInfo, setSelectedPlotInfo] = useState(null);
    const [plotOptions, setPlotOptions] = useState(null);

return (
    <SelectedTypeContext.Provider value={{ selectedType, setSelectedType }}>
        <SelectedMachinesContext.Provider value={{ selectedMachines, setSelectedMachines }}>
            <SelectedStageContext.Provider value={{ selectedStage, setSelectedStage }}>
                <SelectedAxisContext.Provider value={{ selectedAxis, setSelectedAxis }}>
                    <MachineTestsDataContext.Provider value={{ machineTestsData, setMachineTestsData }}>
                        <LoadedSelectionsContext.Provider value={{ loadedSelections, setLoadedSelections }}>
                            <PlotDataContext.Provider value={{ plotData, setPlotData }}>
                                <SelectedPlotInfo.Provider value={{ selectedPlotInfo, setSelectedPlotInfo }}>
                                    <PlotOptionsContext.Provider value={{ plotOptions, setPlotOptions }}>
                                        {children}
                                    </PlotOptionsContext.Provider>
                                </SelectedPlotInfo.Provider>
                            </PlotDataContext.Provider>
                        </LoadedSelectionsContext.Provider>
                    </MachineTestsDataContext.Provider>
                </SelectedAxisContext.Provider>
            </SelectedStageContext.Provider>
        </SelectedMachinesContext.Provider>
    </SelectedTypeContext.Provider>
);
}