/* Impostazioni di visualizzazione del grafico
null | {
    showBenchmarkRangeArea: true | false,
    showCriticalPointsMarkers: true | false,
}
*/
import { createContext } from "react";
const PlotOptionsContext = createContext({
    plotOptions: null,
    setPlotOptions: (options) => {}
  });
export default PlotOptionsContext;