import { confirmAlert } from "react-confirm-alert";

// Mostra messaggio di alert con richiesta di conferma per procedere
export const askConfirm = (title, message, confirmCallback, cancelCallback) => {
    confirmAlert({
        title: title,
        closeOnEscape: true,
        closeOnClickOutside: false,
        keyCodeForClose: [8, 32],
        overlayClassName: "confirmAlert",
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>{title}</h1>
                    <p>{message}</p>
                    <div className="react-confirm-alert-button-group">
                        <button onClick={() => {
                            confirmCallback()
                            onClose();
                        }}>Confirm</button>
                        <button  onClick={() => {
                            cancelCallback()
                            onClose();
                        }}>Cancel</button>
                    </div>
                </div>
            );
        }
    });
}

// Mostra messaggio di alert
export const alertMessage = (title, message) => {
    confirmAlert({
        title: title,
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        overlayClassName: "alertMessage",
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>{title}</h1>
                    <p>{message}</p>
                    <div className="react-confirm-alert-button-group">
                        <button onClick={onClose}>Ok</button>
                    </div>
                </div>
            );
        }
    });
}

// Mostra messaggio di alert per errore nel recupero dei dati
export const alertMissingData = (title, benchmarkErrorMessage, missingDataMessage, missingDataList) => {
    confirmAlert({
        title: title,
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        overlayClassName: "alertMessage",
        customUI: ({ onClose }) => {
            return (
                <div className="react-confirm-alert-body">
                    <h1>{title}</h1>
                    {benchmarkErrorMessage != null && <p>{benchmarkErrorMessage}</p>}
                    {missingDataMessage != null && <p>{missingDataMessage}</p>}
                    {missingDataMessage != null &&
                        <ul>
                            {missingDataList.map((missingData, i) => (
                                <li key={i}>&#x2022; {missingData}</li>
                            ))}
                        </ul>
                    }
                    <div className="react-confirm-alert-button-group">
                        <button className="Ok" onClick={onClose}>Ok</button>
                    </div>
                </div>
            );
        }
    });
}