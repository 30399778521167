/* Impostazioni e dati utilizzati per la visualizzazione del grafico
null | {
    title: titolo,
    data: [dati],
    xAxis: asse X,
    yAxes: [assi Y],
    rightPadding: padding destro del grafico,
}
*/
import { createContext } from "react";
const plotDataContext = createContext({
    plotData: {},
    setPlotData: (data) => {}
  });
export default plotDataContext;