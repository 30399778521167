import { jsPDF } from "jspdf";
import { AXES_LABELS, STAGES_LABELS } from '../data/testOptionsLabels';
import plotInfoSettings from '../pages/DataVisualization/PlotPanel/PlotInfoSettings';
const Plotly = require('plotly.js/lib/core');

// Funzione di supporto per centrare testo nel pdf
const addCenteredText = (doc, text, y) => {
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
}

// Generazione report pdf
export const generatePdfReport = async (type, axis, stage, plotInfo, reportText) => {
    const now = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString();
    
    // Recupero elementi html del grafico e tabella riassuntiva
    const plot = document.getElementById('data-visualization-plot');
    const table = document.getElementById('data-visualization-table');

    // Setting pdf
    const pageWidth = 1540;
    const pageHeigth = 940;

    // Conversione grafico in immagine
    const plotImageData = await Plotly.toImage(plot.children[1], {
        format: 'png',
        width: pageWidth - 40,
        height: pageHeigth - 40,
    });

    // Creazione documento pdf
    const doc = new jsPDF("l", "px", [pageHeigth, pageWidth]);
    let top = pageHeigth / 2 - 300;

    // Aggiungi titolo e sottotitoli
    doc.setFontSize(48);
    addCenteredText(doc, 'Predictive Maintenance Report - Data Visualization', top);
    top = top + 48;
    doc.setFontSize(32);
    doc.text(pageWidth / 2 - 350, top, 'Machine type: ');
    doc.text(pageWidth / 2 - 150, top, type);
    top = top + 32;

    doc.text(pageWidth / 2 - 350, top, 'Axis: ');
    doc.text(pageWidth / 2 - 150, top, AXES_LABELS[axis]);
    top = top + 32;

    doc.text(pageWidth / 2 - 350, top, 'Stage: ');
    doc.text(pageWidth / 2 - 150, top, STAGES_LABELS[axis][stage]);
    top = top + 32;

    doc.text(pageWidth / 2 - 350, top, 'Plot Info: ');
    doc.text(pageWidth / 2 - 150, top, plotInfoSettings[plotInfo]['label']);
    top = top + 32;

    if(reportText !== ''){
        // Inserici testo commento come elemento html
        top = top + 100;
        doc.text(100, top, 'Report Comment: ');
        top = top + 32;
        doc.setFontSize(24);
        doc.html('<div style="white-space: pre-wrap;width: 1340px;">' + reportText + '</div>', {
            callback: function(pdf1) {
                // inserisci tabella e grafico nelle pagine successive
                pdf1.addPage();
                
                pdf1.html(table, {
                    callback: function(pdf2) {
                        pdf2.addPage();
                        pdf2.addImage(plotImageData, "PNG", 20, 20, pageWidth - 40, pageHeigth - 40, `plot`);
                        // Salva documento pdf
                        pdf2.save("PredMaintReport-" + now + ".pdf");
                    },
                    x: 20,
                    y: pageHeigth + 20
        });
            },
            x: 100,
            y: top
        });
    } else { // se non c'è nessun commento inserisci tabella e grafico nelle pagine successive
        doc.addPage();

        doc.html(table, {
            callback: function(pdf) {
                pdf.addPage();
                pdf.addImage(plotImageData, "PNG", 20, 20, pageWidth - 40, pageHeigth - 40, `plot`);
                // Salva documento pdf
                pdf.save("PredMaintReport-" + now + ".pdf");
            },
            x: 20,
            y: pageHeigth + 20
        });
    }
}