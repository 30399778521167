import { Cache } from 'aws-amplify';

// Salva dati nella cache
export const saveData = (type, machine, test, stage, axis, data) => {
    const key = type + '/' + machine + '/' + test + '/' + stage + '/' + axis;
    Cache.setItem(key, data);
}

// Recupera dati dalla cache
export const getData = (type, machine, test, stage, axis) => {
    const key = type + '/' + machine + '/' + test + '/' + stage + '/' + axis;
    return Cache.getItem(key);
}