import { API } from 'aws-amplify';
import { API_NAME } from '../aws-config';
import { getAccessToken, getIdToken } from '../utils/authTokens'
import { getData, saveData } from '../utils/dataCache';
import { alertMissingData } from "../utils/alerts";
import {AXES_LABELS, STAGES_LABELS} from '../data/testOptionsLabels';

// Recupera elenco macchine di un tipo
export const getMachinesOfTypeAPI = async (type) => {
    const IdToken = await getIdToken();
    const headers = {"Authorization": IdToken};
    const response = API.get(API_NAME, 'types/' + type + '/machines', {headers});
    return response;
}

// Recupera i test associati ad una macchina
export const getTestsOfMachineAPI = async (type, machine) => {
    const IdToken = await getIdToken();
    const headers = {"Authorization": IdToken};
    const response = API.get(API_NAME, 'types/' + type + '/machines/' + machine  + '/tests', {headers});
    return response;
}

// Recupera i dati di un test (singolo movimento) da S3
export const getMachineTestDataAPI = async (type, machine, test, stage, axis) => {
    const IdToken = await getIdToken();
    const headers = {"Authorization": IdToken};
    const path = 'types/' + type + '/machines/' + machine + '/tests/' + test + '/axes/' + axis + '/stages/' + stage + '/processed-data';
    const response = API.get(API_NAME, path, {headers});
    return response;
}

// Controlla se i dati sono già nella cache, altrimenti recuperali da S3
export const getMachineTestData = async (type, machine, test, stage, axis) => {
    const cachedData = getData(type, machine, test, stage, axis);
    if(cachedData == null) {
        return await getMachineTestDataAPI(type, machine, test, stage, axis);
    } else {
        return cachedData;
    }
}

// Recupera l'elenco di id dei benchmark range di un tipo di macchina
export const getBenchmarkRagesAPI = async (type) => {
    const IdToken = await getIdToken();
    const headers = {"Authorization": IdToken};
    const response = API.get(API_NAME, 'types/' + type + '/benchmark-ranges', {headers});
    return response;
}

// Recupera tutti i dati necessari per la visualizzazione del grafico
export const getMachineTestsData = async (type, selectedMachines, stage, axis) => {
    let machines = [...selectedMachines];
    let benchmarkRangeId = null;
    let benchmarkErrorMessage = null;
    let missingDataMessage = null;
    // Recupera id dei benchmark ranges e aggiungi alla lista dei test i dati del benchmark.min e benchmark.max
    try {
        const result = await getBenchmarkRagesAPI(type);
        if(result.length === 0) {
            benchmarkErrorMessage = 'No benchmark ranges found for this machine type.';
        } else {
            benchmarkRangeId = result[result.length - 1];
            machines = [{
                machineId: 'benchmark.min',
                testId: benchmarkRangeId,
            }, {
                machineId: 'benchmark.max',
                testId: benchmarkRangeId,
            }, ...selectedMachines];
        }
    } catch (e) {
        console.log(e);
        benchmarkErrorMessage = 'Error while trying to retrieve benchmark range id.';
    }

    // Invia richiesta di recupero dei dati dei test
    const promises = [];
    machines.forEach(machine => {
        promises.push(getMachineTestData(type, machine.machineId, machine.testId, stage, axis));
    });
    
    // Aspetta la risposta di tutte le richieste
    const results = await Promise.all(promises);

    // Aggrega risultati
    const errorDataList = [];
    let machineTestsData = [];
    results.forEach((result, i) => {
        if(typeof(result) !== 'string'){
            machineTestsData.push({
                machineId: machines[i].machineId,
                testId: machines[i].testId,
                data: result
            });
            // Salva dati test nell cache
            saveData(type, machines[i].machineId, machines[i].testId, stage, axis, result);
        } else {
            // Aggiungi test a messaggio di errore se la richiesta è fallita
            errorDataList.push(machines[i].machineId + ' - ' + machines[i].testId);
        }
    });
    // Manda alert con dati che non si sono potuti recuperare
    if(errorDataList.length > 0) {
        missingDataMessage = 'No test data found for ' + type + ', ' + AXES_LABELS[axis] + ', ' + STAGES_LABELS[axis][stage]  + ' for the following machine tests:';
    }
    if(benchmarkErrorMessage != null || errorDataList.length > 0) {
        alertMissingData('Missing Data', benchmarkErrorMessage, missingDataMessage, errorDataList);
    }
    // Separa dati benchmark.min e benchmark.max degli altri test
    let minBenchmarkRange = null;
    let maxBenchmarkRange = null;
    if(machineTestsData.length > 0 && machineTestsData[0].machineId === 'benchmark.min'){
        minBenchmarkRange = machineTestsData[0];
        machineTestsData.shift();
    }
    if(machineTestsData.length > 0 && machineTestsData[0].machineId === 'benchmark.max'){
        maxBenchmarkRange = machineTestsData[0];
        machineTestsData.shift();
    }
    if(minBenchmarkRange == null || maxBenchmarkRange == null) {
        minBenchmarkRange = null;
        maxBenchmarkRange = null;
    }
    return [minBenchmarkRange, maxBenchmarkRange, machineTestsData];
}

// Aggiunga etichetta di test
export const setMachineTestLabelAPI = async (type, machine, test, label) => {
    const AccessToken = await getAccessToken();
    const headers = {"Authorization": AccessToken};
    const body = {"label": label};
    const response = await API.post(API_NAME, 'types/' + type + '/machines/' + machine + '/tests/' + test + '/label', {headers, body});
    if (label !== "none" && label !== "unknown" && !response["Attributes"].hasOwnProperty('split_set'))
        await setMachineTestSetAPI(type, machine, test, 'none')
    return response;
}

// Aggiorna insieme di appartenenz di un test
export const setMachineTestSetAPI = async (type, machine, test, set) => {
    const AccessToken = await getAccessToken();
    const headers = {"Authorization": AccessToken};
    const body = {"set": set};
    const response = API.post(API_NAME, 'types/' + type + '/machines/' + machine + '/tests/' + test + '/set', {headers, body});
    return response;
}

// Inizia elaborazione di un nuovo benchmark range
export const computeBenchmarkRangeAPI =  async (type) => {
    const AccessToken = await getAccessToken();
    const headers = {"Authorization": AccessToken, "InvocationType": "Event"};
    const response = API.get(API_NAME, 'types/' + type + '/benchmark-ranges/compute', {headers});
    return response;
}

// Inizia eliminazione dei dati di un benchmark range
export const deleteBenchmarkRangeDataAPI =  async (type, rangeId) => {
    const AccessToken = await getAccessToken();
    const headers = {"Authorization": AccessToken, "InvocationType": "Event"};
    const response = API.del(API_NAME, 'types/' + type + '/benchmark-ranges/' + rangeId, {headers});
    return response;
}

// Query paginata dei test aventi una certa etichetta
export const getPagedLabeledMachineTestsOfTypeAPI = async (type, label, limit, lastMachineId, lastTestId) => {
    const IdToken = await getIdToken();
    const headers = {"Authorization": IdToken};
    const queryStringParameters =  {"limit": limit};
    let body = {"LastItem": "none"};
    if(lastMachineId != null && lastTestId != null){
        body = {"LastItem": {
            "machine": lastMachineId,
            "machine_test": lastTestId
        }};
    }
    const response = API.post(API_NAME, 'types/' + type + '/machines/tests/label/' + label, {headers, queryStringParameters, body});
    return response;
}

// Query paginata dei test aventi appartenenti ad un certo insieme
export const getPagedSetMachineTestsOfTypeAPI = async (type, split_set, limit, lastMachineId, lastTestId) => {
    const AccessToken = await getAccessToken();
    const headers = {"Authorization": AccessToken};
    const queryStringParameters =  {"limit": limit};
    let body = {"LastItem": "none"};
    if(lastMachineId != null && lastTestId != null){
        body = {"LastItem": {
            "machine": lastMachineId,
            "machine_test": lastTestId
        }};
    }
    const response = API.post(API_NAME, 'types/' + type + '/machines/tests/set/' + split_set, {headers, queryStringParameters, body});
    return response;
}
