import classNames from 'classnames'
import styles from './MachineSelector.module.css'
import Select from 'react-select';

// Selettori della macchina e dei test
export default function MachineSelector({type, machines, selectedMachine, changeMachine, tests, selectedTest, changeTest, selectedMachines}) {

    const mapMachinesListToOptions = (list) => {
        return list.map((element, i) => { return { value: element, label: element }});
    }

    const mapTestListToOptions = (list) => {
        const lableAbbreviations = {
            'benchmark': ' [BM]',
            'anomaly': ' [AN]',
            'unknown': ' [UNK]',
            'none': ''
        };
        const setAbbreviations = {
            'train': '(trn)',
            'test': '(tst)',
            'none': '',
            '': ''
        };

        // Per i test caricati sono mantenuti alcuni dati aggiuntivi come etichetta e set di appartenenza
        return list.map((element, i) => {
            return {
                value: element.test,
                label: element.test + lableAbbreviations[element.label] + setAbbreviations[element.set],
                dataLabel: element.label,
                dataSet: element.set
            }
        });
    }

return (
    <div className={styles.machineSelector}>
        {type == null && <label className={styles.noResultLabel}>Select a type</label>}
        
        {/* Selettore macchina */}
        {type != null && machines.length === 0 && <label className={styles.noResultLabel}>No machines available for this type</label>}
        {machines.length > 0 &&
            <div className={styles.machineSelectWrapper}>
                <label className={classNames(styles.controlPanelLabel, styles.machineSelectLabel)}>Machine</label>
                <Select
                    className={styles.machineSelect}
                    value={selectedMachine} onChange={changeMachine}
                    options={mapMachinesListToOptions(machines)}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'var(--primary-color)',
                            primary75: 'var(--primary-color75)',
                            primary50: 'var(--primary-color50)',
                            primary25: 'var(--primary-color25)',
                            
                        },
                })}/>
            </div>
        }

        {/* Selettore test */}
        {selectedMachine != null && tests.length === 0 && <label className={styles.noResultLabel}>No test available for this machine</label>}
        {selectedMachine != null && tests.length > 0 &&
            <div className={styles.testSelectWrapper}>
                <label className={classNames(styles.controlPanelLabel, styles.testSelectLabel)}>Test</label> 
                <Select
                    className={styles.testSelect}
                    value={selectedTest} onChange={changeTest}
                    options={mapTestListToOptions(tests)}
                    isOptionDisabled={(option) => selectedMachines.some((machine) => machine.machineId === selectedMachine.value && machine.testId === option.value)}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: 'var(--primary-color)',
                            primary75: 'var(--primary-color75)',
                            primary50: 'var(--primary-color50)',
                            primary25: 'var(--primary-color25)',
                            
                        },
                })}/>
            </div>
        }
    </div>
);
}