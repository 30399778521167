import React, { useState, useRef } from "react";
import SelectedTypeContext from "./selectedTypeContext";
import SelectedSetContext from "./selectedSetContext";
import SelectedPageSizeContext from "./selectedPageSizeContext";
import CurrentPageContext from "./currentPageContext";
import IsLastPageContext from "./isLastPageContext";
import PagesLastItemContext from "./pagesLastItemContext";
import HighestPageContext from "./highestPageContext";
import NOfResultsContext from "./nOfResultsContext";

// Componente wrapper per dare accesso ai Context definiti per la pagina
export default function TrainTestSplitContextProvider({children}) {
    // State usati per contenere e modificare i valori dei Context
    const [selectedType, setSelectedType] = useState(null);
    const [selectedSet, setSelectedSet] = useState(null);
    const [selectedPageSize, setSelectedPageSize] = useState({value: 10, label: 10});
    const [currentPage, setCurrentPage] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [highestPage, setHighestPage] = useState(0);
    const [nOfResults, setNOfResults] = useState(0);
    
    const pagesLastItem = useRef([]);

return (
    <SelectedTypeContext.Provider value={{ selectedType, setSelectedType }}>
        <SelectedSetContext.Provider value={{ selectedSet, setSelectedSet }}>
            <SelectedPageSizeContext.Provider value={{ selectedPageSize, setSelectedPageSize }}>
                <CurrentPageContext.Provider value={{ currentPage, setCurrentPage }}>
                    <IsLastPageContext.Provider value={{ isLastPage, setIsLastPage }}>
                        <HighestPageContext.Provider value={{ highestPage, setHighestPage }}>
                            <NOfResultsContext.Provider value={{ nOfResults, setNOfResults }}>
                                <PagesLastItemContext.Provider value={{ pagesLastItem}}>
                                    {children}
                                </PagesLastItemContext.Provider>
                            </NOfResultsContext.Provider>
                        </HighestPageContext.Provider>
                    </IsLastPageContext.Provider>
                </CurrentPageContext.Provider>
            </SelectedPageSizeContext.Provider>
        </SelectedSetContext.Provider>
    </SelectedTypeContext.Provider>
);
}