// Durata dei movimenti in secondi (zoom di default su finestra [0, durata])
const TIME_WINDOW_SETTING = {
    "LaserGeniusPlus_1530": {
        "Axis0001": {
            "Type001": 7,
            "Type002": 7,
            "Type003": 7,
            "Type004": 7,
            "Type005": 7,
            "Type006": 7,
            "Type007": 2,
            "Type008": 2,
            "Type009": 3.5,
            "Type010": 3.5,
            "Type011": 2,
            "Type012": 2,
            "Type013": 2,
            "Type014": 2
        },
        "Axis0002": {
            "Type001": 8,
            "Type002": 8,
            "Type003": 8,
            "Type004": 8,
            "Type005": 8,
            "Type006": 8,
            "Type007": 2,
            "Type008": 2,
            "Type009": 4,
            "Type010": 4,
            "Type011": 2,
            "Type012": 2,
            "Type013": 2,
            "Type014": 2
        },
        "Axis0003": {
            "Type001": 4,
            "Type002": 4,
            "Type003": 4,
            "Type004": 4,
            "Type005": 4,
            "Type006": 4,
            "Type007": 2,
            "Type008": 2,
            "Type009": 3,
            "Type010": 3,
            "Type011": 2,
            "Type012": 2,
            "Type013": 2,
            "Type014": 2
        }
    },
    "LaserGeniusPlus_2040": {
        "Axis0001": {
            "Type001": 13,
            "Type002": 13,
            "Type003": 13,
            "Type004": 13,
            "Type005": 13,
            "Type006": 13,
            "Type007": 5,
            "Type008": 5,
            "Type009": 5,
            "Type010": 5,
            "Type011": 5,
            "Type012": 5,
            "Type013": 5,
            "Type014": 5
        },
        "Axis0002": {
            "Type001": 13,
            "Type002": 13,
            "Type003": 13,
            "Type004": 13,
            "Type005": 13,
            "Type006": 13,
            "Type007": 5,
            "Type008": 5,
            "Type009": 5,
            "Type010": 5,
            "Type011": 5,
            "Type012": 5,
            "Type013": 5,
            "Type014": 5
        },
        "Axis0003": {
            "Type001": 9,
            "Type002": 9,
            "Type003": 9,
            "Type004": 9,
            "Type005": 9,
            "Type006": 9,
            "Type007": 5,
            "Type008": 5,
            "Type009": 5,
            "Type010": 5,
            "Type011": 5,
            "Type012": 5,
            "Type013": 5,
            "Type014": 5
        }
    },
    "LaserGeniusPlus_2060": {
        "Axis0001": {
            "Type001": 13,
            "Type002": 13,
            "Type003": 13,
            "Type004": 13,
            "Type005": 13,
            "Type006": 13,
            "Type007": 4,
            "Type008": 4,
            "Type009": 4,
            "Type010": 4,
            "Type011": 4,
            "Type012": 4,
            "Type013": 4,
            "Type014": 4
        },
        "Axis0002": {
            "Type001": 13,
            "Type002": 13,
            "Type003": 13,
            "Type004": 13,
            "Type005": 13,
            "Type006": 13,
            "Type007": 4,
            "Type008": 4,
            "Type009": 4,
            "Type010": 4,
            "Type011": 4,
            "Type012": 4,
            "Type013": 4,
            "Type014": 4
        },
        "Axis0003": {
            "Type001": 8,
            "Type002": 8,
            "Type003": 8,
            "Type004": 8,
            "Type005": 8,
            "Type006": 8,
            "Type007": 2,
            "Type008": 2,
            "Type009": 4,
            "Type010": 4,
            "Type011": 2,
            "Type012": 2,
            "Type013": 2,
            "Type014": 2
        }
    }
};

export default TIME_WINDOW_SETTING;