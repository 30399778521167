// Impostazioni di visualizzazione per le diverse informazioni dei test
const plotInfoSettings = {
    'speedCmd': {
        'label': 'Speed Cmd',
        'secondaryInfo': ['accCmd'],
        'unit': '[m/min]',
        'secondaryInfoColor': 'indigo',
    },
    'accCmd': {
        'label': 'Acc Cmd',
        'secondaryInfo': ['speedCmd'],
        'unit': '[m/s^2]',
        'secondaryInfoColor': 'crimson',
    },
    'motorTorque': {
        'label': 'Motor Torque',
        'secondaryInfo': ['speedCmd', 'accCmd'],
        'unit': '[Nm]',
        'secondaryInfoColor': null,
    },
    'gearboxTorque': {
        'label': 'Gearbox Torque',
        'secondaryInfo': ['speedCmd', 'accCmd'],
        'unit': '[Nm]',
        'secondaryInfoColor': null,
    },
};

export default plotInfoSettings;