/* Dati dei test caricati per la visualizzazione
null | {
    minRange: dati del benchmark.min,
    maxRange: dati del benchmark.max,
    testsData: [dati test selezionati]
}
*/
import { createContext } from "react";
const machineTestsDataContext = createContext({
    machineTestsData: {},
    setMachineTestsData: (data) => {}
  });
export default machineTestsDataContext;