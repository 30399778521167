import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from './TrainTestSplit.module.css';
import InfoContext from "../../context/InfoContext";
import SelectedTypeContext from "../../context/trainTestSplit/selectedTypeContext"
import SelectedSetContext from "../../context/trainTestSplit/selectedSetContext"
import SelectedPageSizeContext from "../../context/trainTestSplit/selectedPageSizeContext"
import CurrentPageContext from "../../context/trainTestSplit/currentPageContext"
import IsLastPageContext from "../../context/trainTestSplit/isLastPageContext"
import HighestPageContext from "../../context/trainTestSplit/highestPageContext"
import NOfResultsContext from "../../context/trainTestSplit/nOfResultsContext"
import PagesLastItemContext from "../../context/trainTestSplit/pagesLastItemContext"
import MachineTypeSelector from "./MachineTypeSelector/MachineTypeSelector";
import SetSelector from "./SetSelector/SetSelector";
import PageSizeSelector from "./PageSizeSelector/PageSizeSelector";
import PaginatedMachinesTestsList from "./PaginatedMachinesTestsList/PaginatedMachinesTestsList";
import { askConfirm, alertMessage } from "../../utils/alerts";
import {
    getPagedSetMachineTestsOfTypeAPI,
    setMachineTestSetAPI,
} from '../../api/machinesManagementAPI';

// Pagina per visualizzare i test esistenti in base al loro insieme di appartenenza
export default function TrainTestSplitPage() {
    // Context utilizzati
    const {info} = useContext(InfoContext);

    const {selectedType, setSelectedType} = useContext(SelectedTypeContext);
    const {selectedSet, setSelectedSet} = useContext(SelectedSetContext);
    const {selectedPageSize, setSelectedPageSize} = useContext(SelectedPageSizeContext);
    const {currentPage, setCurrentPage} = useContext(CurrentPageContext);
    const {isLastPage, setIsLastPage} = useContext(IsLastPageContext);
    const {highestPage, setHighestPage} = useContext(HighestPageContext);
    const {nOfResults, setNOfResults} = useContext(NOfResultsContext);

    const {pagesLastItem} = useContext(PagesLastItemContext);

    /* Lista di test della pagina corrente
        [] | [test]
    */
    const [machineTests, setMachineTests] = useState([]);
    /* Flag per tenere traccia se si sta ancora attendendo una risposta dall'API Gateway 
        true | false
    */
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();

    // Aggiorna la lista di test quando questa pagina viene caricata
    useEffect(() => {
        if(location.pathname === "/train-test-split" && selectedType != null && selectedSet != null && selectedPageSize != null){
            let lastMachineId = null;
            let lastTestId = null;
            if (currentPage > 0) {
                lastMachineId = pagesLastItem[currentPage-1].machine
                lastTestId = pagesLastItem[currentPage-1].test
            }
            loadSetMachinesTestsOfType(selectedType.value, selectedSet.value, selectedPageSize.value, currentPage, lastMachineId, lastTestId, false, false);
        }
    }, [location]);

    // Esegui query paginata sui test esistenti
    const loadSetMachinesTestsOfType = (type, set, limit, page, lastMachineId, lastTestId, loadingNextPage, resettingPage) => {
        setIsLoading(true)
        getPagedSetMachineTestsOfTypeAPI(type, set, limit, lastMachineId, lastTestId)
        .then((data) => {
            if(data.length > 0) {
                // Aggiorna pagina corrente
                setCurrentPage(page);
                // Se si sta resettando la paginazione imposta il numero di risultati a quelli restituiti
                if(resettingPage)
                    setNOfResults(data.length);
                // Se la pagina caricata è nuova aggiorna la pagina più alta ed il numero di risultati
                    if(page > highestPage) {
                    setHighestPage(page);
                    setNOfResults((n) => n + data.length);
                }
                // Visualizza il risutato
                setMachineTests(data);
                // Se la pagina caricata è nuov memorizza gli id dell'ultimo test della pagina
                if(page+1 > pagesLastItem.length){
                    pagesLastItem.append({
                        'machine': data[data.length-1].machine,
                        'test': data[data.length-1].test
                    });
                } else { // Altrimenti aggiorna gli id dell'ultimo test della pagina
                    pagesLastItem[page] = {
                        'machine': data[data.length-1].machine,
                        'test': data[data.length-1].test
                    };
                }
                // Se ci sono meno risultati della dimensione della pagina marca la pagina come ultima
                if(data.length < limit)
                    setIsLastPage(true);
            } else {
                // Se non ci sono risultati e si stava caricando la pagina successiva marca la pagina come utlima
                if(loadingNextPage)
                    setIsLastPage(true);
                else
                    setMachineTests(data);
            }
            setIsLoading(false)
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve set of machines tests of type.');
            setIsLoading(false)
        });
    }

    // Carica pagina precedente a quella corrente
    const loadPreviousPage = () => {
        if(currentPage > 0) {
            let lastMachineId = null;
            let lastTestId = null;
            if (currentPage > 1) {
                lastMachineId = pagesLastItem[currentPage-2].machine
                lastTestId = pagesLastItem[currentPage-2].test
            }
            loadSetMachinesTestsOfType(selectedType.value, selectedSet.value, selectedPageSize.value, currentPage - 1, lastMachineId, lastTestId, false, false);
        }
    }

    // Carica pagina successiva a quella corrente
    const loadNextPage = () => {
        if(machineTests != null && machineTests.length > 0 && (currentPage < highestPage || !isLastPage)) {
            const lastMachineId = pagesLastItem[currentPage].machine
            const lastTestId = pagesLastItem[currentPage].test
            loadSetMachinesTestsOfType(selectedType.value, selectedSet.value, selectedPageSize.value, currentPage + 1, lastMachineId, lastTestId, true, false);
        }
    }

    // Carica pagina da numero
    const loadPage = (page) => {
        let lastMachineId = null;
        let lastTestId = null;
        if (page > 1) {
            lastMachineId = pagesLastItem[page-1].machine
            lastTestId = pagesLastItem[page-1].test
        }
        loadSetMachinesTestsOfType(selectedType.value, selectedSet.value, selectedPageSize.value, page, lastMachineId, lastTestId, false, false);
    }

    // Resetta stato navigazione e carica prima pagina
    const resetPage = (selectors) => {
        let selType = selectors['type'];
        let selSet = selectors['set'];
        let selPageSize = selectors['pageSize'];
        if(selType == null && selectedType != null)
            selType = selectedType.value
        if(selSet == null && selectedSet != null)
        selSet = selectedSet.value
        if(selPageSize == null && selectedPageSize != null)
            selPageSize = selectedPageSize.value
        if(selType != null && selSet != null && selPageSize != null) {
            setHighestPage(0);
            setNOfResults(0)
            setIsLastPage(false);
            loadSetMachinesTestsOfType(selType, selSet, selPageSize, 0, null, null, false, true);
        }
    }

return (
    <div className={styles.pageContent}>
        <div className={styles.setMachineTestsPanel}>
            <div className={styles.machineListPanel}>
                {info != null && <MachineTypeSelector
                    machineTypes={info.machine_types}
                    type={selectedType}
                    selectType={setSelectedType}
                    resetPage={resetPage}
                />}
                <SetSelector
                    sets={['None', 'Train', 'Test']}
                    set={selectedSet}
                    selectSet={setSelectedSet}
                    resetPage={resetPage}
                />
                <PageSizeSelector
                    pageSizes={['10', '25', '50', '100']}
                    pageSize={selectedPageSize}
                    setPageSize={setSelectedPageSize}
                    resetPage={resetPage}
                />
            </div>
            <PaginatedMachinesTestsList
                machineTests={machineTests}
                selectedType={selectedType}
                selectedSet={selectedSet}
                page={currentPage}
                isLastPage={isLastPage}
                highestPage={highestPage}
                nOfResults={nOfResults}
                loadPreviousPage={loadPreviousPage}
                loadNextPage={loadNextPage}
                loadPage={loadPage}
                isLoading={isLoading}
            />
        </div>
    </div>
);
}