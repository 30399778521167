// specifica motore
const MS2N_SPECS = {
    'torque_conversion_X': 38.2,
    'torque_conversion_Y': 16.3,
    'torque_conversion_Z': 1.45,
    'ratio': 7,
    'motor_inertia_X': 0.003,
    'motor_inertia_Y': 0.00089,
    'gearbox_inertia_X': 0.000945,
    'gearbox_inertia_Y': 0.000238,
    'efficiency': 0.97,
    'max_torque_gearbox': 352,
};

export default MS2N_SPECS;