import { Outlet, Link, useLocation } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import styles from './Layout.module.css'
import InfoContext from "../../context/InfoContext";
import IsAdminContext from "../../context/isAdminContext";
import { getInfoAPI } from "../../api/generalAPI";
import { alertMessage } from "../../utils/alerts";

// Layout di base dell'applicazione, in base allo state nel router cambia il contenuto della pagina visualizzata
const Layout = ({signOut, user}) => {
    const location = useLocation();
    const {setInfo} = useContext(InfoContext);
    const {isAdmin, setIsAdmin} = useContext(IsAdminContext);

    // Controlla se l'utente che ha fatto l'accesso è un admin
    const checkUserIsAdmin = () => {
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        if(typeof groups !== "undefined"){
            setIsAdmin(groups.includes('admins'))
        }
    }

    // Recupera informazioni generali
    const retrieveGeneralInfo = () => {
        getInfoAPI()
        .then((data) => {
            setInfo(data)
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve general info.')
        });
    }

    // Ogni volta che la pagina/componente è caricata recupera informazioni generali e leggi il livello di accesso dell'utente
    useEffect(() => {
        retrieveGeneralInfo();
        checkUserIsAdmin();
    }, [user]);

    // Ritorna le classi CSS per l'elemento della barra di navigazione
    const getMenuOptionClasses = (current) => {
        let classNames = [styles.menuOption]
        // Se la pagina è quella attualmente selezionata modifica lo stile di base
        if(current)
            classNames.push(styles.currentMenuOption)
        return classNames.join(' ')
    }

  return (
    <>
        <nav className={styles.menuBar}>
            <div>
                <h1 className={styles.appTitle}>R&D Predictive Maintenance</h1>
                {/* Barra di navigazione */}
                <ul className={styles.mainMenu}>
                    <li className={getMenuOptionClasses(location.pathname === "/data-visualization")}>
                        {/* Link di navigazine a pagina */}
                        <Link to="/data-visualization">Data Visualization</Link>
                    </li>
                    <li className={getMenuOptionClasses(location.pathname === "/data-labels")}>
                        <Link to="/data-labels">Data Labels</Link>
                    </li>
                    {isAdmin &&
                        <li className={getMenuOptionClasses(location.pathname === "/train-test-split")}>
                            <Link to="/train-test-split">Train-Test Split</Link>
                        </li>
                    }
                    {isAdmin &&
                        <li className={getMenuOptionClasses(location.pathname === "/benchmark-ranges")}>
                            <Link to="/benchmark-ranges">Benchmark Ranges</Link>
                        </li>
                    }
                </ul>
            </div>
            <button className={styles.logoutButton} onClick={signOut}>Sign out</button>
        </nav>
        
        {/* Componente caricato dal router di navigazione */}
        <Outlet/>
    </>
  )
};

export default Layout;