// Impostazioni per i servizi AWS di API Gateway e Cognito
export const API_NAME = "pred-maint-web-app"

const awsConfig =  {
    Auth: {
        region: "eu-central-1",
        userPoolId: "eu-central-1_pKosub7jA",
        userPoolWebClientId: "4e5u2vn89t2nbkc7s46dbicoh4"
    },
    API: {
        endpoints: [
            {
              name: API_NAME,
              endpoint: "https://lohpvxwsa4.execute-api.eu-central-1.amazonaws.com/Phase1/"
            }
        ]
    }
}

export default awsConfig
