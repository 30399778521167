import React, { useContext, useEffect } from "react";
import styles from './PaginatedMachinesTestsList.module.css'
import classNames from 'classnames';
import InfoContext from "../../../context/InfoContext";
import SelectedMachinesContext from '../../../context/dataVisualization/selectedMachinesContext';

// Lista dei test e navigazione delle pagine dei risultati
export default function PaginatedMachineTestsList({machineTests, selectedType, selectedSet, page, isLastPage, highestPage, nOfResults, loadPreviousPage, loadNextPage, loadPage, isLoading}) {
    const {info} = useContext(InfoContext);
    const {selectedMachines, setSelectedMachines} = useContext(SelectedMachinesContext);

    // Listener che inizializza il Context dei test selezionati della pagina di visualizzazione
    // Necessario nel caso questa pagina sia caricata prima della pagina di visualizzazione
    useEffect(() => {
        if(info != null && selectedMachines == null){
            let selMachines = {};
            info.machine_types.forEach((type) => {
                selMachines[type] = [];
            });
            setSelectedMachines(selMachines);
        }
    }, [info]);

    // Metodo per ottenere dal numero di pagina corrente i numeri di pagina adiacenti
    const getPageNumbersDisplay = (pageNumber) => {
        let pageNumbersDisplay = [];
        for(let i = -4; i <= 4; i = i + 1){
            pageNumbersDisplay.unshift(pageNumber - i)
        }
        return pageNumbersDisplay.filter((x) => x > 0 && x < highestPage)
    }

    // Aggiungi test alla lista di test selezionati del Context della pagina di visualizzazione
    const addToSelectedMachines = (type, machineId, testId, label, set) => {
        if(!selectedMachines[type].some((machine) => machine.machineId === machineId && machine.testId === testId)){
            setSelectedMachines((machines) => {
                let newMachines = {};
                info.machine_types.forEach((type) => {
                    newMachines[type] =  Array.from(machines[type]);
                });
                newMachines[type].push({
                    machineId,
                    testId,
                    selected: true,
                    label: label,
                    set: set
                });
                return newMachines;
            });
        }
    }

    // Rimuovi test dalla lista di test selezionati del Context della pagina di visualizzazione
    const removeFromSelectedMachines = (type, machineId, testId, label) => {
        if(selectedMachines[type].some((machine) => machine.machineId === machineId && machine.testId === testId)){
            setSelectedMachines((machines) => {
                let newMachines = {};
                info.machine_types.forEach((type) => {
                    newMachines[type] =  Array.from(machines[type]);
                });
                const index = newMachines[selectedType.value].findIndex((selMachine) =>  selMachine.machineId === machineId && selMachine.testId === testId)
                newMachines[selectedType.value].splice(index, 1);
                return newMachines;
            });
        }
    }

return (
    <div className={styles.listPanel}>
        {((selectedType == null || selectedSet == null) || (machineTests != null && machineTests.length > 0)) &&
            <div className={styles.tablePanel}>
                {/* Counter del numero dei risultati totali */}
                {selectedType != null && selectedSet != null && machineTests != null && machineTests.length > 0 &&
                    <label className={styles.nOfResultsLabel}>N. of results: <strong>{nOfResults}{isLastPage? '' : '...'}</strong></label>
                }

                {/* Lista dei test della pagina corrente */}
                <table className={styles.table}>
                    <thead className={styles.tableHead}>
                        <tr>
                            <th className={styles.tableHeadCell}>Machine Id</th>
                            <th className={styles.tableHeadCell}>Test Id</th>
                            <th className={styles.tableHeadCell}>Label</th>
                            <th className={styles.smallTableHeadCell}></th>
                        </tr>
                    </thead>
                    {machineTests != null && machineTests.length > 0 &&
                        <tbody className={styles.tableBody}>
                            {machineTests.map((machineTest, i) => (  
                                <tr  key={machineTest.machine + machineTest.test} className={i % 2 === 0 ? styles.evenTableRow: styles.oddTableRow}>
                                    <td className={styles.tableCell}>{machineTest.machine}</td>
                                    <td className={styles.tableCell}>{machineTest.test}</td>
                                    <td className={styles.tableCell}>{machineTest.label}</td>
                                    {/* Bottone per aggiungere o rimuovere test da quelli selezionati */}
                                    <td className={styles.tableCell}>
                                        {selectedMachines != null && !selectedMachines[selectedType.value].some((machine) => machine.machineId === machineTest.machine && machine.testId === machineTest.test) &&
                                            <button className={classNames(styles.addToSelectedButton, styles.toolTipButton)}
                                                onClick={() => {addToSelectedMachines(selectedType.value, machineTest.machine, machineTest.test, machineTest.label, selectedSet.value)}}
                                                disabled={selectedMachines == null || selectedMachines[selectedType.value].some((machine) => machine.machineId === machineTest.machine && machine.testId === machineTest.test)}
                                            ><span className={styles.toolTipText}>Add to Selected Machines</span></button>
                                        }
                                        {selectedMachines != null && selectedMachines[selectedType.value].some((machine) => machine.machineId === machineTest.machine && machine.testId === machineTest.test) &&
                                            <button className={classNames(styles.removeFromSelectedButton, styles.toolTipButton)}
                                                onClick={() => {removeFromSelectedMachines(selectedType.value, machineTest.machine, machineTest.test, machineTest.label)}}
                                                disabled={selectedMachines == null || !selectedMachines[selectedType.value].some((machine) => machine.machineId === machineTest.machine && machine.testId === machineTest.test)}
                                            ><span className={styles.toolTipText}>Remove from Selected Machines</span></button>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }

                    {/* Placeholder per lista dei test */}
                    {(machineTests == null || machineTests.length === 0) &&
                        <tbody className={styles.tableBody}>
                            <tr className={styles.tableRow}>  
                                <td colSpan="4" className={classNames(styles.tableCell, styles.tableEmptyCell)}></td> 
                            </tr>
                        </tbody>
                    }
                </table>

                {/* Barra di navigazione */}
                <div className={styles.pageNavigationPanel}>
                    {/* Pulsante pagina precedente */}
                    <button
                        className={styles.pageNavigationButton}
                        onClick={() => {loadPreviousPage()}}
                        disabled={page === 0}
                    >{'<'}</button>
                    {/* Numeri di pagine */}
                    <div className={styles.pageNavigationNumbersBarWrapper}>
                        <div className={styles.pageNavigationNumbersBar}>
                            {/* Pulsante prima pagina (fisso) */}
                            <button
                                className={styles.pageNavigationNumber}
                                onClick={() => {loadPage(0)}}
                                disabled={page === 0}
                            >1</button>
                            {page > 5 && 
                                <label className={styles.pageNavigationLabel}>...</label>
                            }

                            {/* Pulsanti per pagine adiacenti a quella corrente */}
                            {getPageNumbersDisplay(page).map((pageNumber) => (
                                <button key={pageNumber}
                                    className={styles.pageNavigationNumber}
                                    onClick={() => {loadPage(pageNumber)}}
                                    disabled={page === pageNumber}
                                >{pageNumber+1}</button>
                            ))}

                            {/* Pulsante ultima pagina (fisso) */}
                            {highestPage !== 0 && highestPage - page > 5 && 
                                <label className={styles.pageNavigationLabel}>...</label>
                            }
                            {highestPage !== 0 &&
                                <button
                                    className={styles.pageNavigationNumber}
                                    onClick={() => {loadPage(highestPage)}}
                                    disabled={page === highestPage}
                                >{highestPage + 1}</button>
                            }
                            {!isLastPage && 
                                <label className={styles.pageNavigationLabel}>...</label>
                            }
                        </div>
                    </div>
                    {/* Pulsante pagina successiva */}
                    <button
                        className={styles.pageNavigationButton}
                        onClick={() => {loadNextPage()}}
                        disabled={machineTests.length === 0 || (page === highestPage && isLastPage)}
                    >{'>'}</button>
                </div>
            </div>
        }
        {selectedType != null && selectedSet != null && machineTests != null && machineTests.length === 0 && isLoading &&
            <label className={styles.controlPanelLabel}>Loading ...</label>
        }
        {selectedType != null && selectedSet != null && machineTests != null && machineTests.length === 0 && !isLoading &&
            <label className={styles.controlPanelLabel}>No machine tests of type "{selectedType.value}" found for "{selectedSet.value}" set</label>
        }
    </div>
);
}