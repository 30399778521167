import React, { useContext, useState, useEffect } from "react";
import styles from './BenchmarkRanges.module.css';
import InfoContext from "../../context/InfoContext";
import MachineTypeSelector from "./MachineTypeSelector/MachineTypeSelector";
import BenchmarkRangesList from "./BenchmarkRangesList/BenchmarkRangesList";
import { askConfirm, alertMessage } from "../../utils/alerts";
import {
    getBenchmarkRagesAPI,
    computeBenchmarkRangeAPI,
    getPagedSetMachineTestsOfTypeAPI,
    deleteBenchmarkRangeDataAPI
} from '../../api/machinesManagementAPI';

// Pagina di gestione dei Benchmark Range
export default function BenchmarkRangesPage() {
    // Context utilizzati
    const {info} = useContext(InfoContext);

    /* Tipo di macchina selezionato
    null | {"value": machine_type, ...}
    */
    const [selectedType, setSelectedType] = useState(null);
    /* Numero di bechmark per un tipo di macchine
    0 | 1 | 2 | 3
    */
    const [nOfBenchmarkMachines, setNOfBenchmarkMachines] = useState(0);
    /* Numero di bechmark per un tipo di macchine
    [] | ["range_id"]
    */
    const [benchmarkRanges, setBenchmarkRanges] = useState([]);
    /* Flag che tiene traccia se è in corso l'invio della richiesta di computazione di un nuovo range
    true | false
    */
    const [computeRangeProcessing, setComputeRangeProcessing] = useState(false);

    // Listener per caricare i benchmark range e controllare se ci sono sufficienti benchmark per generarne uno
    // quando un tipo di macchine viene selezionato
    useEffect(() => {
        if(selectedType != null){
            setBenchmarkRanges([]);
            loadBenchmarkRangesOfType(selectedType.value);
            getNOfBenchmarkMachinesOfType(selectedType.value);
        }
    }, [selectedType]);

    // Recupera benchmark range di un tipo
    const loadBenchmarkRangesOfType = (type) => {
        getBenchmarkRagesAPI(type)
        .then((data) => {
            setBenchmarkRanges([...data].reverse());
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve benchmark ranges.');
        });
    }

    // Controlla se ci sono almeno 3 test becnhmark nel training set per un tipo di macchine con la query di test paginata
    const getNOfBenchmarkMachinesOfType = (type) => {
        getPagedSetMachineTestsOfTypeAPI(type, 'train', 3, null, null)
        .then((data) => {
            setNOfBenchmarkMachines(data.length);
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve benchmark machines of type.');
        });
    }

    // Inizia computazione di un nuovo benchmark range
    // State computeRangeProcessing disabilita bottone momentaneamente per evitare doppi click
    const computeBenchmarkRanges = (type) => {
        setComputeRangeProcessing(true);
        computeBenchmarkRangeAPI(type)
        .then((data) => {
            alertMessage('Computing Benchmark Range', 'Computation of ' + type + ' Benchmark Range started. The process will take a couple of minutes.');
            setComputeRangeProcessing(false);
        })
        .catch((error) => {
            setComputeRangeProcessing(false);
            console.log(error);
            alertMessage('Error', 'Error while trying to compute new benchmark ranges.');
        });
    }

    // Inizio eliminazione dei dati per un bechmark range
    const deleteBenchmarkRangeData = (index) => {
        askConfirm('Delete Benchmark Range', 'Are you sure you want to delete the data of this benchmark range?', () => {
            deleteBenchmarkRangeDataAPI(selectedType.value, benchmarkRanges[index])
            .then((data) => {
                setBenchmarkRanges((ranges) => {
                    let newRanges = Array.from(ranges);
                    newRanges.splice(index, 1);
                    return newRanges;
                });
            })
            .catch((error) => {
                console.log(error);
                alertMessage('Error', 'Error while trying to delete benchmark range data.');
            });
        },
        () => {});
    }

return (
    <div className={styles.pageContent}>
        <div className={styles.benchmarkRangesPanel}>
            {info != null && <MachineTypeSelector
                machineTypes={info.machine_types}
                type={selectedType}
                selectType={setSelectedType}
            />}

            {/* Lista di bechmark range esistenti */}
            { selectedType != null && benchmarkRanges?.length === 0 &&
                <p className={styles.panelContent}>No Benchmark Range found for this type</p>
            }
            { (selectedType == null || benchmarkRanges?.length > 0) &&
                <BenchmarkRangesList
                    benchmarkRanges={benchmarkRanges}
                    deleteRange={deleteBenchmarkRangeData}
                />
            }
        </div>
        
        <hr className={styles.separator}/>

        {/* Pulsante per avviare la computazione di un nuovo becnhmark range */}
        <div className={styles.computeBenchmarkRangesPanel}>
            <p className={styles.panelTitle}>Compute Benchmark Range</p>
            {selectedType == null &&
                <p className={styles.panelContent}>Select a type</p>
            }
            {selectedType != null && nOfBenchmarkMachines < 2 &&
                <p className={styles.panelContent}>{"There aren't enough benchmark machines in the Training Set of the selected type (min: 2)"}</p>
            }
            <button
                className={styles.computeBenchmarkRangesButton}
                onClick={() => computeBenchmarkRanges(selectedType.value)}
                disabled={selectedType == null || nOfBenchmarkMachines < 2 || computeRangeProcessing}
            >Compute</button>
        </div>
    </div>
);
}