import React, { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from './DataLabels.module.css';
import InfoContext from "../../context/InfoContext";
import SelectedTypeContext from "../../context/dataLabels/selectedTypeContext"
import SelectedLabelContext from "../../context/dataLabels/selectedLabelContext"
import SelectedPageSizeContext from "../../context/dataLabels/selectedPageSizeContext"
import CurrentPageContext from "../../context/dataLabels/currentPageContext"
import IsLastPageContext from "../../context/dataLabels/isLastPageContext"
import HighestPageContext from "../../context/dataLabels/highestPageContext"
import NOfResultsContext from "../../context/dataLabels/nOfResultsContext"
import PagesLastItemContext from "../../context/dataLabels/pagesLastItemContext"
import MachineTypeSelector from "./MachineTypeSelector/MachineTypeSelector";
import LabelSelector from "./LabelSelector/LabelSelector";
import PageSizeSelector from "./PageSizeSelector/PageSizeSelector";
import PaginatedMachinesTestsList from "./PaginatedMachinesTestsList/PaginatedMachinesTestsList";
import { askConfirm, alertMessage } from "../../utils/alerts";
import {
    getPagedLabeledMachineTestsOfTypeAPI,
    setMachineTestLabelAPI,
} from '../../api/machinesManagementAPI';

// Pagina per visualizzare i test esistenti in base alla loro etichetta
export default function DataLabelsPage() {
    // Context utilizzati
    const {info} = useContext(InfoContext);

    const {selectedType, setSelectedType} = useContext(SelectedTypeContext);
    const {selectedLabel, setSelectedLabel} = useContext(SelectedLabelContext);
    const {selectedPageSize, setSelectedPageSize} = useContext(SelectedPageSizeContext);
    const {currentPage, setCurrentPage} = useContext(CurrentPageContext);
    const {isLastPage, setIsLastPage} = useContext(IsLastPageContext);
    const {highestPage, setHighestPage} = useContext(HighestPageContext);
    const {nOfResults, setNOfResults} = useContext(NOfResultsContext);

    const {pagesLastItem} = useContext(PagesLastItemContext);

    /* Lista di test della pagina corrente
        [] | [test]
    */
    const [machineTests, setMachineTests] = useState([]);

    const location = useLocation();

    // Aggiorna la lista di test quando questa pagina viene caricata
    useEffect(() => {
        if(location.pathname === "/data-labels" && selectedType != null && selectedLabel != null && selectedPageSize != null){
            let lastMachineId = null;
            let lastTestId = null;
            if (currentPage > 0) {
                lastMachineId = pagesLastItem[currentPage-1].machine
                lastTestId = pagesLastItem[currentPage-1].test
            }
            loadLabeledMachinesTestsOfType(selectedType.value, selectedLabel.value, selectedPageSize.value, currentPage, lastMachineId, lastTestId, false, false);
        }
    }, [location]);

    // Esegui query paginata sui test esistenti
    const loadLabeledMachinesTestsOfType = (type, label, limit, page, lastMachineId, lastTestId, loadingNextPage, resettingPage) => {
        getPagedLabeledMachineTestsOfTypeAPI(type, label, limit, lastMachineId, lastTestId)
        .then((data) => {
            if(data.length > 0) {
                // Aggiorna pagina corrente
                setCurrentPage(page);
                // Se si sta resettando la paginazione imposta il numero di risultati a quelli restituiti
                if(resettingPage)
                    setNOfResults(data.length);
                // Se la pagina caricata è nuova aggiorna la pagina più alta ed il numero di risultati
                if(page > highestPage) {
                    setHighestPage(page);
                    setNOfResults((n) => n + data.length);
                }
                // Visualizza il risutato
                setMachineTests(data);
                // Se la pagina caricata è nuov memorizza gli id dell'ultimo test della pagina
                if(page+1 > pagesLastItem.length){
                    pagesLastItem.append({
                        'machine': data[data.length-1].machine,
                        'test': data[data.length-1].test
                    });
                } else { // Altrimenti aggiorna gli id dell'ultimo test della pagina
                    pagesLastItem[page] = {
                        'machine': data[data.length-1].machine,
                        'test': data[data.length-1].test
                    };
                }
                // Se ci sono meno risultati della dimensione della pagina marca la pagina come ultima
                if(data.length < limit)
                    setIsLastPage(true);
            } else {
                // Se non ci sono risultati e si stava caricando la pagina successiva marca la pagina come utlima
                if(loadingNextPage)
                    setIsLastPage(true);
                else
                    setMachineTests(data);
            }
        })
        .catch((error) => {
            console.log(error);
            alertMessage('Error', 'Error while trying to retrieve labeled machines tests of type.');
        });
    }

    // Carica pagina precedente a quella corrente
    const loadPreviousPage = () => {
        if(currentPage > 0) {
            let lastMachineId = null;
            let lastTestId = null;
            if (currentPage > 1) {
                lastMachineId = pagesLastItem[currentPage-2].machine
                lastTestId = pagesLastItem[currentPage-2].test
            }
            loadLabeledMachinesTestsOfType(selectedType.value, selectedLabel.value, selectedPageSize.value, currentPage - 1, lastMachineId, lastTestId, false, false);
        }
    }

    // Carica pagina successiva a quella corrente
    const loadNextPage = () => {
        if(machineTests != null && machineTests.length > 0 && (currentPage < highestPage || !isLastPage)) {
            const lastMachineId = pagesLastItem[currentPage].machine
            const lastTestId = pagesLastItem[currentPage].test
            loadLabeledMachinesTestsOfType(selectedType.value, selectedLabel.value, selectedPageSize.value, currentPage + 1, lastMachineId, lastTestId, true, false);
        }
    }

    // Carica pagina da numero
    const loadPage = (page) => {
        let lastMachineId = null;
        let lastTestId = null;
        if (page > 1) {
            lastMachineId = pagesLastItem[page-1].machine
            lastTestId = pagesLastItem[page-1].test
        }
        loadLabeledMachinesTestsOfType(selectedType.value, selectedLabel.value, selectedPageSize.value, page, lastMachineId, lastTestId, false, false);
    }

    // Resetta stato navigazione e carica prima pagina
    const resetPage = (selectors) => {
        let selType = selectors['type'];
        let selLabel = selectors['label'];
        let selPageSize = selectors['pageSize'];
        if(selType == null && selectedType != null)
            selType = selectedType.value
        if(selLabel == null && selectedLabel != null)
            selLabel = selectedLabel.value
        if(selPageSize == null && selectedPageSize != null)
            selPageSize = selectedPageSize.value
        if(selType != null && selLabel != null && selPageSize != null) {
            setHighestPage(0);
            setNOfResults(0)
            setIsLastPage(false);
            loadLabeledMachinesTestsOfType(selType, selLabel, selPageSize, 0, null, null, false, true);
        }
    }

return (
    <div className={styles.pageContent}>
        <div className={styles.labeledMachineTestsPanel}>
            <div className={styles.machineListPanel}>
                {info != null && <MachineTypeSelector
                    machineTypes={info.machine_types}
                    type={selectedType}
                    selectType={setSelectedType}
                    resetPage={resetPage}
                />}
                <LabelSelector
                    labels={['None', 'Benchmark', 'Anomaly', 'Unknown']}
                    label={selectedLabel}
                    selectLabel={setSelectedLabel}
                    resetPage={resetPage}
                />
                <PageSizeSelector
                    pageSizes={['10', '25', '50', '100']}
                    pageSize={selectedPageSize}
                    setPageSize={setSelectedPageSize}
                    resetPage={resetPage}
                />
            </div>
            <PaginatedMachinesTestsList
                machineTests={machineTests}
                selectedType={selectedType}
                selectedLabel={selectedLabel}
                page={currentPage}
                isLastPage={isLastPage}
                highestPage={highestPage}
                nOfResults={nOfResults}
                loadPreviousPage={loadPreviousPage}
                loadNextPage={loadNextPage}
                loadPage={loadPage}
            />
        </div>
    </div>
);
}